import client from './client';
import { withErrorHandling } from './errorHandling';

// 피드백 리스트
type postFeedbackListType = {
  centerId: string;
  failedToComplete: string;
  orderBy: string;
  responseData: boolean | null;
  limit: number;
  offset: number;
};
const postFeedbackListRaw = (data: postFeedbackListType) =>
  client.post('/api/app/feedback/list', data);
export const postFeedbackList = withErrorHandling(postFeedbackListRaw);

// 피드백 상세
type postFeedbackType = {
  params: {
    feedbackId: string;
  };
  data: {
    centerId: string;
    sessionId: string;
  };
};
const postFeedbackRaw = ({ params, data }: postFeedbackType) =>
  client.post(`/api/app/feedback/${params.feedbackId}`, data);
export const postFeedback = withErrorHandling(postFeedbackRaw);

// 피드백 응답
type postFeedbackResponseType = {
  params: {
    feedbackId: string;
  };
  data: {
    centerId: string;
    sessionId: string;
    feedbackResContent: string;
    feedbackResImg?: {
      fileName: string;
    } | null;
    feedbackResVod?: {
      fileName: string;
    } | null;
  };
};
const postFeedbackResponseRaw = ({ params, data }: postFeedbackResponseType) =>
  client.post(`/api/app/feedback/${params.feedbackId}/response`, data);
export const postFeedbackResponse = withErrorHandling(postFeedbackResponseRaw);

// 피드백 사진/비디오 완료
type postFeedbackCompleteType = {
  params: {
    feedbackId: string;
  };
  data: {
    centerId: string;
    sessionId: string;
    resNo: string;
  };
};
const postFeedbackCompleteRaw = ({ params, data }: postFeedbackCompleteType) =>
  client.post(
    `/api/app/feedback/${params.feedbackId}/response/uploadCompleted`,
    data
  );
export const postFeedbackComplete = withErrorHandling(postFeedbackCompleteRaw);
