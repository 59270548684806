import axios, { AxiosError } from 'axios';

import { useAccessTokenStore, useRefreshTokenStore } from 'store/auth';
import { getTokenRefresh } from 'apis/auth';
import { useLoadingStore } from 'store/loading';

const client = axios.create();

client.defaults.baseURL = process.env.REACT_APP_API_URL;
client.defaults.withCredentials = true;

// interceptors request
client.interceptors.request.use(
  (config) => {
    useLoadingStore.getState().beginRequest();
    if (!config.headers) return config;

    const { accessToken } = useAccessTokenStore.getState();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    useLoadingStore.getState().endRequest();
    return Promise.reject(error);
  }
);

// interceptors response
client.interceptors.response.use(
  (response) => {
    useLoadingStore.getState().endRequest();
    return response;
  },
  async (error) => {
    useLoadingStore.getState().endRequest();
    if (!error.response) {
      return Promise.reject(error);
    }

    // accessToken 재발급
    if (error.response.status === 401) {
      return await tokenRefresh(error);
    }

    return Promise.reject(error);
  }
);

let isAlreadyFetchingAccessToken = false;
let subscribers: ((accessToken: string) => void)[] = [];

const subscribeTokenRefresh = (cb: (accessToken: string) => void) => {
  subscribers.push(cb);
};

const onAccessTokenFetched = (accessToken: string) => {
  subscribers.forEach((callback) => {
    callback(accessToken);
  });
  subscribers = [];
};

const tokenRefresh = async (error: AxiosError): Promise<any> => {
  const { response } = error;

  if (!response) {
    return Promise.reject(error);
  }

  const retryOriginalRequest = new Promise((resolve, reject) => {
    subscribeTokenRefresh((accessToken: string) => {
      if (response.config.headers) {
        response.config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      client(response.config).then(resolve).catch(reject);
    });
  });
  if (isAlreadyFetchingAccessToken) {
    return retryOriginalRequest;
  } else {
    isAlreadyFetchingAccessToken = true;
    const { refreshToken } = useRefreshTokenStore.getState();
    try {
      const response = await getTokenRefresh(refreshToken);
      useAccessTokenStore.setState({
        accessToken: response.accessToken,
      });
      useRefreshTokenStore.setState({
        refreshToken: response.refreshToken,
      });
      onAccessTokenFetched(response.accessToken);
    } catch (refreshError) {
      return Promise.reject(refreshError);
    } finally {
      isAlreadyFetchingAccessToken = false;
    }
    return client(response.config);
  }
};

export default client;
