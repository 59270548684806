import { logout } from 'utils/logout';
import client from './client';

export const getTokenRefresh = (refreshToken: string) =>
  client
    .post(
      '/api/center/reissue-submit',
      {},
      {
        headers: {
          refreshToken: `Bearer ${refreshToken}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((e) => {
      alert('로그인 세션이 만료되었습니다. 다시 로그인해주세요.');
      logout();
      return new Error(e);
    });
