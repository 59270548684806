import {
  postFeedback,
  postFeedbackComplete,
  postFeedbackResponse,
} from 'apis/feedback';
import FeedbackVideoContainer from 'components/FeedbackVideoContainer';
import Icon from 'components/Icon';
import MyProfileImage from 'components/MyProfileImage';
import { NotificationLine } from 'components/NotificationLine';
import VideoPlayer from 'components/VideoPlayer';
import { UPLOAD_ALERT_TEXT, VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';
import { getRoleDisplayName, uploadS3 } from 'constants/common';
import { FeedbackType, SceneType } from 'constants/type';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import styled from 'styled-components';
import { DeleteButton } from 'styles/FormStyle';
import { CircleImage, Flex, NullDataBox, Span } from 'styles/style';
const DetailFeedbackPage = () => {
  const navigate = useNavigate();

  //auth
  const { center } = useCenterStore();
  const { profile } = useProfileStore();

  const [feedback, setFeedback] = useState<FeedbackType>();
  const [feedbackResContent, setFeedbackResContent] = useState<string>('');
  const [media, setMedia] = useState<File>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('sessionId');
  const feedbackId = searchParams.get('feedbackId');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    if (!feedbackId || !sessionId) {
      return;
    }
    const response = await postFeedback({
      params: { feedbackId },
      data: {
        centerId: center.centerId,
        sessionId,
      },
    });
    const result = response;
    if (!result) {
      navigate(-1);
      return;
    }

    const { feedbackView } = result;
    setFeedback(feedbackView);
  };
  const addMedia = (file: File) => {
    if (file.size > 1 * 1024 * 1024 * 1024) {
      alert(VIDEOUPLOAD_ALERT_TEXT);
      return;
    }
    setMedia(file);
  };
  const submit = async () => {
    if (feedbackResContent === '') {
      alert('응답을 남겨주세요');
      return;
    }
    if (!feedbackId || !sessionId) {
      return;
    }
    setIsLoading(true);
    const response = await postFeedbackResponse({
      params: {
        feedbackId,
      },
      data: {
        sessionId,
        centerId: center.centerId,
        feedbackResContent,
        ...(media && media.type.startsWith('image/')
          ? { feedbackResImg: { fileName: media.name } }
          : { feedbackResImg: null }),
        ...(media && media.type.startsWith('video/')
          ? { feedbackResVod: { fileName: media.name } }
          : { feedbackResVod: null }),
      },
    });

    const result = response;
    if (!result) return;
    const { feedbackVideoPresignedUrl, feedbackImagePresignedUrl, responseId } =
      result;

    if (media && feedbackImagePresignedUrl) {
      uploadS3(feedbackImagePresignedUrl, media)
        .then(() => {
          feedbackId && sendComplete(feedbackId, responseId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        });
    }
    if (media && feedbackVideoPresignedUrl) {
      uploadS3(feedbackVideoPresignedUrl, media)
        .then(() => {
          feedbackId && sendComplete(feedbackId, responseId);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);
          alert(UPLOAD_ALERT_TEXT);
        });
    }
    if (!media) {
      setIsLoading(false);
      navigate(0);
    }
  };

  const sendComplete = async (feedbackId: string, resNo: string) => {
    if (!sessionId) return;
    const response = postFeedbackComplete({
      params: { feedbackId },
      data: {
        centerId: center.centerId,
        sessionId,
        resNo,
      },
    });
    setIsLoading(false);
    navigate(0);
  };

  const onChangeFeedbackResContent = useCallback((e: any) => {
    setFeedbackResContent(e.target.value);
  }, []);
  return (
    <>
      {feedback && (
        <Container>
          <Flex margin='0 0 15px 0'>
            <Span fontSize='18px' fontWeight='700' whiteSpace='nowrap'>
              피드백 보기
            </Span>
          </Flex>
          {!feedback.response && (
            <Notification>
              <Icon icon='Alert' width={17} height={17} />
              회원님이 피드백을 요청하였습니다. 피드백을 남겨주세요
            </Notification>
          )}
          <UserInfoContainer>
            <CircleImage>
              <img
                src={
                  feedback.requestedBy?.profileImage?.thumbnailImage ||
                  '/defaultUserImage.png'
                }
              />
            </CircleImage>
            <Flex flexDirection='column' gap='3px'>
              <Span>{feedback.requestedBy.nickname}</Span>
              <Span fontSize='14px' color='var(--c400)'>
                멤버
              </Span>
            </Flex>
          </UserInfoContainer>

          {feedback.feedbackStatus !== 'completed' && (
            <NotificationLine
              margin='20px 0 -10px 0'
              type='alert'
              text='영상을 변환하고 있는 중입니다...'
            />
          )}
          {feedback.scene?.additionalVideo ? (
            <FeedbackVideoContainer
              feedbackVideo={feedback.scene.additionalVideo}
              userVideo={feedback.feedbackVideo}
            />
          ) : (
            <NullDataBox>미디어를 불러오는데 실패했습니다</NullDataBox>
          )}

          <TextArea value={feedback.feedbackContent} readOnly={true} />

          <UserInfoContainer>
            <CircleImage>
              <MyProfileImage />
            </CircleImage>
            <Flex flexDirection='column' gap='3px'>
              <Span>{profile.nickname}</Span>
              <Span fontSize='14px' color='var(--c400)'>
                {getRoleDisplayName(profile.role)}
              </Span>
            </Flex>
          </UserInfoContainer>
          <TextArea
            placeholder='회원님의 요청에 응답을 남겨주세요'
            value={feedback.response?.content}
            onChange={onChangeFeedbackResContent}
            readOnly={!!feedback.response}
          />
          <input
            id='feedbackMedia'
            accept='image/*, video/*'
            type='file'
            style={{ display: 'none' }}
            onChange={(e) => e.target.files && addMedia(e.target.files[0])}
          />

          {!feedback.response &&
            (media ? (
              <MediaContainer>
                <MediaWrapper>
                  {media.type.startsWith('image/') ? (
                    <img src={URL.createObjectURL(media)} />
                  ) : (
                    <video controls src={URL.createObjectURL(media)} />
                  )}
                </MediaWrapper>

                <DeleteButton onClick={() => setMedia(undefined)}>
                  <Icon icon='X' width={9.8} height={9.8} />
                </DeleteButton>
              </MediaContainer>
            ) : (
              <label htmlFor='feedbackMedia'>
                <AddMediaButton>
                  <Icon icon='Plus' width={10} height={10} />
                  <Span>미디어 추가하기</Span>
                </AddMediaButton>
              </label>
            ))}
          {feedback.response &&
            (feedback.response.video || feedback.response.image) && (
              <MediaContainer>
                <MediaWrapper>
                  {feedback.response.video ? (
                    <VideoPlayer
                      src={feedback.response.video?.streamingUrl}
                      controls={true}
                    />
                  ) : (
                    <img src={feedback.response.image?.thumbnailImage} />
                  )}
                </MediaWrapper>
              </MediaContainer>
            )}
          {!feedback.response && (
            <SubmitButton
              disabled={isLoading}
              type='button'
              onClick={() => submit()}
            >
              피드백 남기기
            </SubmitButton>
          )}
        </Container>
      )}
    </>
  );
};
export default DetailFeedbackPage;

const Container = styled.div`
  width: 414px; //Media의 width 350px을 지키기 위한 wdith
  padding: 32px;
  background-color: var(--background-color);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  cursor: default;
`;
const Notification = styled.div`
  color: var(--semantic-red);
  margin-bottom: 35px;
  display: flex;
  gap: 5px;
  font-size: 1.4rem;
  align-items: center;
`;
const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 12px;
  margin: 15px 0;
  border: 1px solid var(--c500);
  background-color: transparent;
  padding: 15px;
  color: white;
  resize: none;
  line-height: 20px;
`;
const AddMediaButton = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  background-color: var(--c700);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--c300);
  font-size: 1.6rem;
  cursor: pointer;
`;
const SubmitButton = styled.button`
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  color: black;
  margin-top: 30px;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
`;
const MediaContainer = styled.div`
  position: relative;
  width: 100%;
`;
const MediaWrapper = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;
