import {
  Title,
  Container,
  Contents,
  TermBody,
  TermTitle,
  TermBox,
  Dl,
  Dt,
  Dd,
  Table,
  Th,
  Td,
} from 'styles/TermStyle';
import { Margin } from 'styles/style';

export const Term3 = () => {
  return (
    <Container>
      <Title>Real-Feed E-mail 및 SMS 광고성 정보 수신 동의</Title>
      <Margin margin='0 0 20px 0' />
      <Dd>
        Real-Feed를 이용해 주셔서 감사합니다. 저희는 광고 및 프로모션 정보를
        제공하고자 합니다. 광고성 정보를 받아보시고자 하신다면, 아래 내용을
        확인하시고 동의해 주십시오.
      </Dd>
      <Margin margin='0 0 20px 0' />
      <TermBox>
        <Dl>
          <Dt>1. 광고성 정보의 이용목적</Dt>
          <Dd>
            ㈜스판링크가 제공하는 이용자 맞춤형 서비스, 뉴스레터 발송, 새로운
            기능의 안내, 각종 경품 행사, 이벤트 등의 광고성 정보를 전자우편이나
            서신우편, 문자(SMS), 푸시 등을 통해 이용자에게 제공합니다. 이메일 및
            SMS 수신거부와 관계없이 광고나 영리성 목적 외의 약관안내 및
            서비스내용, 회사의 주요 정책 관련 변경에 따른 안내 등 의무적으로
            안내되어야 하는 메일은 정상적으로 발송됩니다. 마케팅 수신 동의는
            거부하실 수 있으며 동의 이후에라도 고객의 의사에 따라 동의를 철회할
            수 있습니다. 동의를 거부하시더라도 ㈜스판링크가 제공하는 서비스의
            이용에 제한이 되지 않습니다. 단, 이벤트 및 이용자 맞춤형 상품 추천
            등의 마케팅 정보 안내 서비스가 제한됩니다. 이메일 수신동의를
            하셨음에도 이메일을 받지 못하고 계신다면, 아래 내용을 확인해 주세요.
            - 이메일 주소가 잘못 등록되어 있을 경우 - 해당 사이트 이메일이
            스팸메일로 설정되어 있을 경우 - 위 사항을 점검했음에도 문제 해결이
            안 되신 경우, 고객센터로 연락 주세요. ‘수신거부'로 변경하여도 수정
            전에 예약발송 메일 또는 SMS가 설정되어 있어 약 일주일 동안은 메일
            또는 SMS가 발송될 수 있습니다. 이 점 양해 부탁드립니다.
          </Dd>
          <Dt>2. 미동의 시 불이익 사항</Dt>
          <Dd>
            개인정보보호법 제22조 제5항에 의해 선택정보 사항에 대해서는 동의
            거부하시더라도 서비스 이용에 제한되지 않습니다. 단, 이벤트 및 이용자
            맞춤형 상품 추천 등의 마케팅 정보 안내 서비스가 제한됩니다.
          </Dd>
          <Dt>3. 서비스 정보 수신 동의 철회</Dt>
          <Dd>
            ㈜스판링크에서 제공하는 마케팅 정보를 원하지 않을 경우
            admin@whistle-pro.com로 철회를 요청할 수 있습니다
          </Dd>
        </Dl>
      </TermBox>
    </Container>
  );
};
