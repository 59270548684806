import { Flex, PreLineDiv, Span } from 'styles/style';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import {
  ProfileImageWrapper,
  ProfileContainer,
  CommentBox,
  Content,
  StateItem,
  StateContainer,
  ButtonWrapper,
  TextArea,
  GrayButton,
  GreenButton,
} from '../CommentContainer/style';
import { useEffect, useRef, useState } from 'react';
import { timeAgo } from 'constants/common';
import Icon from 'components/Icon';
import { ToggleMenu } from 'components/ToggleMenu';
import { ReplyType } from 'constants/type';
import { postAddLike, postEditReply } from 'apis/community';
type SubReplyItemType = {
  reply: ReplyType;
  menuOpenId: string;
  setMenuOpenId: React.Dispatch<React.SetStateAction<string>>;
};
export const SubReplyItem = ({
  reply: reply_prop,
  menuOpenId,
  setMenuOpenId,
}: SubReplyItemType) => {
  const { center } = useCenterStore();
  const { accessToken } = useAccessTokenStore();
  const [reply, setReply] = useState<ReplyType>(reply_prop);
  const [replyContent, setReplyContent] = useState<string>(reply.content);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const textAreaRef_reply = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = (
    isInit: boolean,
    textAreaRef: React.MutableRefObject<HTMLTextAreaElement | null>
  ) => {
    if (textAreaRef.current && isInit) {
      textAreaRef.current.style.height = `20px`;
    } else if (textAreaRef.current) {
      textAreaRef.current.style.height = 'inherit';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };
  const handleThumbsUp = async (reply: ReplyType) => {
    const response = await postAddLike({
      centerId: center.centerId,
      postId: reply.postId,
      replyId: reply.replyId,
      isLikeData: !reply.isLikeExist,
    });
    const result = response;
    if (!result) return;

    const updatedReply = {
      ...reply,
      isLikeExist: !reply.isLikeExist,
      likeCount: reply.isLikeExist ? reply.likeCount - 1 : reply.likeCount + 1,
    };
    setReply(updatedReply);
  };

  const editComment = async (reply: ReplyType) => {
    const response = await postEditReply({
      params: {
        postId: reply.postId,
      },
      data: {
        centerId: center.centerId,
        content: replyContent,
        orderBy: 'desc',
        replyId: reply.replyId,
      },
    });
    const result = response;
    if (!result) return;

    const { replyview } = result;
    setReply(replyview);
    setIsEdit(false);
    setReplyContent('');
    adjustHeight(true, textAreaRef_reply);
  };
  return (
    <CommentBox key={reply.postId}>
      <ProfileContainer>
        <ProfileImageWrapper>
          <img
            src={
              reply.authorProfile?.profileImage?.thumbnailImage ||
              '/defaultUserImage.png'
            }
            alt='프로필 이미지'
          />
        </ProfileImageWrapper>
        {reply.authorProfile?.nickname}
        {!reply.isDeleted && (
          <Flex
            position='relative'
            flex={1}
            justifyContents='right'
            alignItems='flex-start'
          >
            <Flex
              onClick={(e) => {
                e.stopPropagation();
                if (menuOpenId === '' || menuOpenId !== reply.replyId) {
                  setMenuOpenId(reply.replyId);
                } else {
                  setMenuOpenId('');
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon icon='DotMenu' width={20} height={20} />
            </Flex>
            {reply.replyId === menuOpenId && (
              <ToggleMenu
                isMyPost={reply.isMine}
                postId={reply.postId}
                replyId={reply.replyId}
                setIsEdit={setIsEdit}
                setMenuOpenId={setMenuOpenId}
              />
            )}
          </Flex>
        )}
      </ProfileContainer>
      {reply.isDeleted ? (
        <Content isDeleted={reply.isDeleted}>삭제된 댓글입니다</Content>
      ) : (
        <Content isDeleted={reply.isDeleted}>
          {isEdit ? (
            <Flex
              flexDirection='column'
              border='1px solid var(--c700)'
              padding='10px'
              borderRadius='3px'
            >
              <TextArea
                rows={1}
                ref={textAreaRef_reply}
                onInput={() => adjustHeight(false, textAreaRef_reply)}
                onChange={(e) => setReplyContent(e.target.value)}
                value={replyContent}
                placeholder='댓글을 남겨주세요'
              />
              <Flex flexDirection='row' gap='7px' justifyContents='right'>
                <GrayButton
                  onClick={() => {
                    setIsEdit(false);
                    setReplyContent(reply.content);
                    adjustHeight(true, textAreaRef_reply);
                  }}
                >
                  취소
                </GrayButton>
                <GreenButton
                  isActive={replyContent !== ''}
                  onClick={() => editComment(reply)}
                >
                  저장
                </GreenButton>
              </Flex>
            </Flex>
          ) : (
            <PreLineDiv>{reply.content}</PreLineDiv>
          )}
        </Content>
      )}
      <StateContainer>
        <StateItem>{timeAgo(reply.createdAt)}</StateItem>
        {reply.isLikeExist ? (
          <StateItem>
            <Span color='var(--primary)'>좋아요</Span>
            <ButtonWrapper onClick={() => handleThumbsUp(reply)}>
              <Icon icon='ThumbsUp_Active' width={13} height={13} />
            </ButtonWrapper>
            <Span color='var(--primary)'>{reply.likeCount}</Span>
          </StateItem>
        ) : (
          <StateItem>
            <Span>좋아요</Span>
            <ButtonWrapper onClick={() => handleThumbsUp(reply)}>
              <Icon icon='ThumbsUp' width={13} height={13} />
            </ButtonWrapper>
            <Span>{reply.likeCount}</Span>
          </StateItem>
        )}
      </StateContainer>
    </CommentBox>
  );
};
