import styled from 'styled-components';
export const FilteringContainer = styled.div`
  font-size: 1.8rem;
  color: #8a8c99;
  display: flex;
  gap: 10px;
`;
type FilteringItemType = {
  active: boolean;
};
export const FilteringItem = styled.div<FilteringItemType>`
  font-weight: ${({ active }) => (active ? 600 : 400)};
  color: ${({ active }) => (active ? 'white' : '#8A8C99')};
  white-space: nowrap;
  cursor: pointer;
`;
export const Container = styled.div`
  display: flex;
  gap: 23px;
  margin-top: 23px;
  flex-wrap: wrap;
`;
export const SessionContainer = styled.div<{ image: string }>`
  width: 200px;
  height: 347px;
  margin-top: 7px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-image: url(${({ image }) => image});
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 20px 20px 0px rgba(0, 0, 0, 0.5) inset,
    0 -30px 30px 0px rgba(0, 0, 0, 0.8) inset;
  cursor: pointer;
`;
export const SessionTitle = styled.div`
  width: 150px;
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: -1px;
  margin-top: 15px;
  color: white;
  text-align: center;
`;
export const SessionTime = styled.div`
  color: var(--c300);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -1px;
  margin-top: 8px;
  margin-bottom: 24px;
`;
const Label = styled.div`
  width: 48px;
  height: 21px;
  border-radius: 6.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.7px;
  color: black;
  white-space: nowrap;
`;
export const PublicLabel = styled(Label)`
  background: #f5ce42;
`;
export const PrivateLabel = styled(Label)`
  background-color: var(--primary);
`;
export const AddSession = styled.div`
  width: 200px;
  height: 347px;
  margin-top: 7px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #2c2d34;
  color: white;
  gap: 20px;
  cursor: pointer;
  > span {
    text-align: center;
  }
`;
