import { SportsTagType } from 'constants/type';
import * as S from './style';
import { useEffect, useState } from 'react';
import { postSportsTag } from 'apis/resource';
type CenterTagsType = {
  isEdit: boolean;
  selectedTag: string[];
  setSelectedTag: React.Dispatch<React.SetStateAction<string[]>>;
};
const CenterTags = ({
  isEdit,
  selectedTag,
  setSelectedTag,
}: CenterTagsType) => {
  const [sportsTags, setSportsTags] = useState<SportsTagType[]>([]);

  //auth
  const clickHandler = (tagId: string) => {
    if (selectedTag.find((v) => v === tagId)) {
      let arr = selectedTag.filter((v) => v !== tagId);
      setSelectedTag(arr);
    } else {
      setSelectedTag((prev) => [...prev, tagId]);
    }
  };

  useEffect(() => {
    getSportsTag();
  }, []);

  const getSportsTag = async () => {
    try {
      const response = await postSportsTag();
      const { sportsTag } = response;
      setSportsTags(sportsTag);
    } catch (e) {
      console.error('getSportsTag error');
      setSportsTags([]);
    }
  };
  return (
    <S.TagContainer>
      {sportsTags.length > 0 &&
        sportsTags.map((sportsTag: SportsTagType, index: number) => {
          return (
            <S.TagImageContainer
              key={index}
              active={selectedTag.includes(sportsTag.tagId)}
              onClick={() => isEdit && clickHandler(sportsTag.tagId)}
            >
              <S.TagImage src={sportsTag?.tagImage?.mediumImage || ''} />
              <S.TagName>{sportsTag.tagName}</S.TagName>
            </S.TagImageContainer>
          );
        })}
    </S.TagContainer>
  );
};
export default CenterTags;
