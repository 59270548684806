import uuid from 'react-uuid';
import { Flex, Span } from 'styles/style';
import * as S from './style';
import { CursorWrapper } from 'styles/style';
import { CaptionType } from 'constants/type';
import { Input } from 'styles/FormStyle';
import Icon from 'components/Icon';
import CaptionIconBox from 'components/CaptionIconBox';
import { useEffect, useRef, useState } from 'react';
import { Error } from 'styles/FormStyle';
import {
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';
type CaptionsType = {
  captions: CaptionType[];
  setCaptions: React.Dispatch<React.SetStateAction<CaptionType[]>>;
  isEdit?: boolean;
  errors: FieldErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
};

const Captions = ({
  captions,
  setCaptions,
  isEdit,
  errors,
  setError,
  clearErrors,
}: CaptionsType) => {
  const [openBoxId, setOpenBoxId] = useState<number>();
  const captionIconBoxRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        captionIconBoxRef.current &&
        !captionIconBoxRef.current.contains(event.target) &&
        openBoxId !== undefined
      ) {
        setOpenBoxId(undefined); // CaptionIconBox를 숨기기 위해 상태를 초기화합니다.
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openBoxId]);
  useEffect(() => {
    checkValidate()
      ? clearErrors('captions')
      : setError(`captions`, {
          type: 'manual',
          message: '캡션의 이름를 입력해주세요',
        });
  }, [captions, setError]);
  const checkValidate = () => {
    // 각 caption의 text를 검사
    let errorCheck = true;
    captions.forEach((caption, index) => {
      if (!caption.text.trim()) {
        errorCheck = false;
      }
    });
    return errorCheck;
  };
  const deleteCaptions = (index: number) => {
    const newCaptions = captions.filter((v, i) => i !== index);
    setCaptions(newCaptions);
  };
  const addCaption = () => {
    const newCaption: CaptionType = {
      text: '',
      iconId: 'I0001',
      iconImageUrl:
        'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/center_caption_clock.png',
    };
    setCaptions((prev) => [newCaption, ...prev]);
  };
  const changeText = (e: any, i: number) => {
    const newCaptions = [...captions];
    newCaptions[i].text = e.target.value;
    setCaptions(newCaptions);
    // if (e.target.value.trim()) {
    //   clearErrors(`captions[${i}]`);
    // }
  };
  const changeIcon = (iconId: string, iconUrl: string, i: number) => {
    const newCaptions = [...captions];
    newCaptions[i].iconImageUrl = iconUrl;
    newCaptions[i].iconId = iconId;
    setCaptions(newCaptions);
    setOpenBoxId(undefined);
  };
  return (
    <S.CaptionContainer>
      {isEdit && (
        <CursorWrapper>
          <Span onClick={() => addCaption()}>추가하기</Span>
        </CursorWrapper>
      )}
      {captions.map((caption: CaptionType, index: number) => {
        const name = `caption${index}`;
        return (
          <Flex key={name} flexDirection='column' gap='10px'>
            <S.CaptionItem>
              <S.CaptionIconWrapper
                onClick={() => isEdit && setOpenBoxId(index)}
              >
                {(
                  <img
                    src={caption.iconImageUrl}
                    alt='캡션 이미지'
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null; // 재귀적인 호출을 방지
                      target.src =
                        'https://whistlestorage.s3.ap-northeast-2.amazonaws.com/center_caption_clock.png';
                    }}
                  />
                ) || (
                  <div>
                    <Span>+</Span>
                    <Span>아이콘</Span>
                  </div>
                )}
              </S.CaptionIconWrapper>
              <Input
                name={name}
                value={caption.text}
                placeholder='캡션의 이름을 입력해주세요'
                readOnly={!isEdit}
                onChange={(e) => changeText(e, index)}
              />
              {isEdit && (
                <CursorWrapper onClick={() => deleteCaptions(index)}>
                  <Icon
                    icon='X_2'
                    width={13}
                    height={13}
                    color={`var(--semantic-red)`}
                  />
                </CursorWrapper>
              )}
              {openBoxId === index && (
                <CaptionIconBox
                  openBoxId={openBoxId}
                  changeIcon={changeIcon}
                  ref={captionIconBoxRef}
                />
              )}
            </S.CaptionItem>
            {errors[`captions[${index}]`] && (
              <Error role='alert'>
                {errors[`captions[${index}]`]?.message?.toString()}
              </Error>
            )}
          </Flex>
        );
      })}
      {errors[`captions`] && (
        <Error role='alert'>{errors[`captions`]?.message?.toString()}</Error>
      )}
    </S.CaptionContainer>
  );
};
export default Captions;
