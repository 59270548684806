import styled from 'styled-components';

export const VideoWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid var(--c500);
`;
export const HorizontalBox = styled(VideoWrapper)`
  width: 140px;
  height: 79px;
  * {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
export const VerticalBox = styled(VideoWrapper)`
  width: 80px;
  height: 141px;
  margin: 0 30px;
  * {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
export const AddSceneText = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  cursor: default;
`;
export const Input = styled.input`
  /* max-width: 333px; */
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: white;
  border: 1px solid var(--c500);
  border-radius: 10px;
  background-color: transparent;
`;
export const TimeInput = styled.input`
  width: 30px;
  text-align: center;
  background-color: transparent;
  color: white;
  border: 1px solid var(--c500);
  border-radius: 4px;
  margin-right: 4px;
`;
