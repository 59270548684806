import { ButtonWrapper } from 'components/CommentContainer/style';
import Icon from 'components/Icon';
import { ProfileImage } from 'components/Navigation/style';
import { ToggleMenu } from 'components/ToggleMenu';
import VideoPlayer from 'components/VideoPlayer';
import { timeAgo } from 'constants/common';
import { PostType } from 'constants/type';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleImage, Flex, PreLineDiv, Span } from 'styles/style';
import {
  ContentContainer,
  ContentWrapper,
  ItemContainer,
  NotiButton,
  StateContainer,
  StateItem,
  ThumbImageWrapper,
  TopContainer,
} from './style';
type CommunityItemProps = PostType & {
  menuOpenId: string;
  setMenuOpenId: React.Dispatch<React.SetStateAction<string>>;
};

const CommunityItem = ({
  menuOpenId,
  setMenuOpenId,
  ...post
}: CommunityItemProps) => {
  const timeStamp = new Date().getTime();

  return (
    <>
      {post.isDeleted ? (
        <ItemContainer>
          <CircleImage>
            <img src='/defaultUserImage.png' />
          </CircleImage>
          <ContentContainer>
            <ContentWrapper>삭제된 게시글입니다</ContentWrapper>
          </ContentContainer>
        </ItemContainer>
      ) : (
        <ItemContainer>
          <Flex flexDirection='column' gap='14px'>
            <CircleImage>
              <img
                src={
                  post.authorProfile?.profileImage?.thumbnailImage ||
                  '/defaultUserImage.png'
                }
                alt='프로필 이미지'
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null; // 재귀적인 호출을 방지
                  target.src = '/defaultUserImage.png';
                }}
              />
            </CircleImage>
            {post.isNoti && <NotiButton>공지</NotiButton>}
          </Flex>
          <Flex flexDirection='column' gap='10px'>
            <TopContainer>
              <Span fontSize='14px' color='white'>
                {post.authorProfile?.nickname}
              </Span>
              <Span fontSize='12px' color='var(--c500)'>
                {timeAgo(post.createdAt)}
              </Span>
            </TopContainer>
            <ContentContainer>
              <ContentWrapper>
                <PreLineDiv>{post.content}</PreLineDiv>
              </ContentWrapper>
            </ContentContainer>
            <ThumbImageWrapper>
              {post.image && post.image.thumbnailImage && (
                <img
                  src={`${post.image.thumbnailImage}?${timeStamp}`}
                  alt='thumbnail'
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.style.display = 'none'; // 이미지를 숨깁니다.
                  }}
                />
              )}
              {post.video && post.video.streamingUrl && (
                <VideoPlayer src={post.video.streamingUrl} controls={true} />
              )}
            </ThumbImageWrapper>
            <StateContainer>
              <StateItem>
                {post.isLikeExist ? (
                  <>
                    <Icon icon='ThumbsUp_Active' width={17} height={15} />
                    <Span color='var(--primary)'>{post.likeCount}</Span>
                  </>
                ) : (
                  <>
                    <Icon icon='ThumbsUp' width={17} height={15} />
                    <Span color='white'>{post.likeCount}</Span>
                  </>
                )}
              </StateItem>
              <StateItem>
                <Icon icon='Message' width={16} height={16} />
                <Span color='white'>{post.replyCount}</Span>
              </StateItem>
            </StateContainer>
          </Flex>
          <Flex
            position='relative'
            flex={1}
            justifyContents='right'
            alignItems='flex-start'
          >
            <Flex
              onClick={(e) => {
                e.stopPropagation();
                if (menuOpenId === '' || menuOpenId !== post.postId) {
                  setMenuOpenId(post.postId);
                } else {
                  setMenuOpenId('');
                }
              }}
            >
              <Icon icon='DotMenu' width={20} height={20} />
            </Flex>
            {post.postId === menuOpenId && (
              <ToggleMenu
                isMyPost={post.isMine}
                postId={post.postId}
                editUrl={`/center/community/edit?postId=${post.postId}`}
              />
            )}
          </Flex>
        </ItemContainer>
      )}
    </>
  );
};
export default CommunityItem;
