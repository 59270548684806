import { useCenterStore, useProfileStore } from 'store/auth';

const MyProfileImage = () => {
  const { profile } = useProfileStore();
  console.log(profile);
  return (
    <img
      src={profile?.profileImage?.thumbnailImage || '/defaultUserImage.png'}
      alt='프로필 이미지'
      onError={(e) => {
        const target = e.target as HTMLImageElement;
        target.onerror = null; // 재귀적인 호출을 방지
        target.src = '/defaultUserImage.png';
      }}
    />
  );
};

export default MyProfileImage;
