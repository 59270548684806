import { PreperationType, SceneType } from 'constants/type';
import client from './client';
import { withErrorHandling } from './errorHandling';

// 세션 리스트
type postSessionListType = {
  centerId: string;
  failedToComplete: boolean;
  authority: string;
  limit: number;
  offset: number;
  orderBy: string;
};
const postSessionListRaw = (data: postSessionListType) =>
  client.post('/api/session/list', data);
export const postSessionList = withErrorHandling(postSessionListRaw);

// 세션 상세
type postSessionType = {
  params: {
    sessionId: string;
  };
  data: {
    centerId: string;
  };
};
const postSessionRaw = ({ params, data }: postSessionType) =>
  client.post(`/api/session/${params.sessionId}`, data);
export const postSession = withErrorHandling(postSessionRaw);

// 세션 생성
type postCreateSessionType = {
  hlsStreamingFile?: string;
  scenesList: SceneType[];
  sessionVo: {
    centerId: string;
    title: string;
    introduceText: string;
    introducesTitle: string;
    introducesContext: string;
    preperationIds: PreperationType[];
    streamingDuration: number;
    streamingRatio: 'landscape' | 'portrait';
    sessionImages: string;
    authority: string;
    metadata: {
      originalWidth: number;
      originalHeight: number;
    };
    isFliped: false;
  };
  coachingFile?: string;
};
const postCreateSessionRaw = (data: postCreateSessionType) =>
  client.post('/api/session/createSession', data);
export const postCreateSession = withErrorHandling(postCreateSessionRaw);

// 세션 수정
type postUpdateSessionType = {
  params: {
    sessionId: string;
  };
  data: {
    scenesList: SceneType[];
    sessionVo: {
      centerId: string;
      title: string;
      introduceText: string;
      introducesTitle: string;
      introducesContext: string;
      preperationIds: PreperationType[];
      deletePreperationList: { iconNo: string | null | undefined }[];
      sessionImages: string | null | undefined;
      deleteSessionImages: string | null | undefined;
      authority: string;
      deleteScenes: { sceneId: string | undefined }[];
      isFliped: false;
      originalWidth: number;
      originalHeight: number;
    };
  };
};
const postUpdateSessionRaw = ({ params, data }: postUpdateSessionType) =>
  client.post(`/api/session/${params.sessionId}/update`, data);
export const postUpdateSession = withErrorHandling(postUpdateSessionRaw);

// 세션 삭제
type postDeleteSessionType = {
  params: {
    sessionId: string;
  };
};
const postDeleteSessionRaw = ({ params }: postDeleteSessionType) =>
  client.post(`/api/admin/center/${params.sessionId}/delete`);
export const postDeleteSession = withErrorHandling(postDeleteSessionRaw);

// 세션 비디오 업로드 완료
type postSessionCompleteType = {
  sessionId: string;
  centerId: string;
};
const postSessionCompleteRaw = (data: postSessionCompleteType) =>
  client.post('/api/session/sessionUpdateCompleted', data);
export const postSessionComplete = withErrorHandling(postSessionCompleteRaw);
