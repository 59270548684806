import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
type TagImageContainerType = {
  active: boolean;
};
export const TagImageContainer = styled.div<TagImageContainerType>`
  position: relative;
  width: 82px;
  height: 82px;
  border-radius: 100%;
  overflow: hidden;
  border: ${({ active }) => (active ? '1px solid white' : 'none')};
  cursor: pointer;
`;
export const TagName = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
`;
export const TagImage = styled.img`
  opacity: 50%;
`;
