import styled, { StyledComponent } from 'styled-components';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0px;
  }
`;
export const InputName = styled.div`
  color: #fff;
  font-size: 1.4rem;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.5px;
  white-space: nowrap;
`;
export const Input = styled.input`
  display: flex;
  padding: 16px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--grayscale-c-500, #8a8c99);
  color: white;
  background-color: transparent;
  flex: 1;
`;
export const TextArea = styled.textarea`
  height: 161px;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid var(--grayscale-c-500, #8a8c99);
  color: white;
  background-color: transparent;
  resize: none;
`;

export const AddImage = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--c500);
  color: var(--c500);
  text-align: center;
  gap: 6px;
  font-size: 1.4rem;
  cursor: pointer;
`;
export const UploadImageContainer = styled.div`
  position: relative;
`;
export const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid white;
  overflow: hidden;
`;
export const DeleteButton = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: var(--c300);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

type ErrorType = {
  margin?: string;
};
export const Error = styled.small<ErrorType>`
  text-align: left;
  font-size: 1.3rem;
  color: var(--semantic-red);
  margin: ${({ margin }) => margin};
  line-height: 19px;
`;

export const PrepareContainer = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;
type PrepareImageWrapperType = {
  width: string;
  height: string;
  active?: boolean;
  cursor?: string;
  backgroundColor?: string;
};
export const PrepareImageWrapper = styled.div<PrepareImageWrapperType>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 100%;
  position: relative;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#272727'};
  border: ${({ active }) => active && '1px solid var(--primary)'};
  padding: 14px;
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
`;
export const PrepareRequiredBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--primary);
  font-size: 9px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
`;
export const PrepareText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  color: var(--c100);
  text-align: center;
`;
export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  padding: 5px 0 7px;
`;
type ButtonType = {
  width?: string;
};
const Button = styled.button<ButtonType>`
  display: flex;
  width: ${({ width }) => width || '450px'};
  padding: 16px 0px 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--primary);
  color: black;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.5px;
`;
type SubmitButtonType = {
  color?: string;
};
export const SubmitButton = styled(Button)<SubmitButtonType>`
  background: var(--primary);
  color: ${({ color }) => (color ? color : 'black')};
`;
export const WhiteBorderButton = styled(Button)`
  border: 1px solid white;
  background-color: transparent;
  color: white;
`;
export const TimerSpan = styled.span`
  position: absolute;
  right: 10px;
  color: var(--semantic-red);
  top: 50%;
  transform: translateY(-50%);
`;
