import styled from 'styled-components';

type ContainerType = {
  backgroundColor?: string;
  margin?: string;
};
export const Container = styled.div<ContainerType>`
  width: 100%;
  padding: 9px 14px;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--c900)'};
  gap: 10px;
  margin: ${({ margin }) => margin};
  border-radius: 10px
`;
