import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Span, Head, Margin } from '../../styles/style';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import DaumPostcodeEmbed from 'react-daum-postcode';
import {
  Form,
  InputName,
  AddImage,
  UploadImageContainer,
  InputContainer,
  ImageWrapper,
  DeleteButton,
  SubmitButton,
  TextArea,
  IconWrapper,
  Error,
  ImageContainer,
  WhiteBorderButton,
} from 'styles/FormStyle';
import {
  Container,
  AddressFindButton,
  PostcodeWrapper,
  TagContainer,
} from '../ApplyCenterForm/style';
import Header from 'components/Header';
import Icon from '../Icon';
import { ImageType, SportsTagType, CaptionType } from 'constants/type';
import { allowedImageFormats, uploadS3 } from 'constants/common';
import { CaptionModal } from 'components/Modals/CaptionModal';
import { UPLOAD_ALERT_TEXT } from 'constants/alertText';
import { TermsWrapper } from './style';
import CenterTags from 'components/CenterTags';
import Terms from 'components/Terms';
import Input from 'components/Input';
import Captions from 'components/Captions';
import { postCenterView, postUpdateAcceptCenter } from 'apis/center';

function EditCenterFormAfter() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { isSubmitting, isDirty, errors, dirtyFields },
  } = useForm();
  const [sportsTags, setSportsTags] = useState<SportsTagType[]>([]);
  const [selectedTag_init, setSelectedTag_init] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [profileFiles, setProfileFiles] = useState<File[]>([]);
  const [profileFiles_init, setProfileFiles_init] = useState<ImageType[]>([]);
  const [deleteBannerImages, setDeleteBannerImages] = useState<string[]>([]);
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [address1, setAddress1] = useState<string>('');
  const [captions_init, setCaptions_init] = useState<any[]>([]);
  const [captions, setCaptions] = useState<CaptionType[]>([]);
  const [resultTerms, setResultTerms] = useState<{
    [key: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //auth
  const { center, setCenter } = useCenterStore();
  const { profile } = useProfileStore();

  //대표자 정보
  const [ownerName, setownerName] = useState<string>('');
  const [ownerPhone, setOwnerPhone] = useState<string>('');
  //사업자 등록증
  const [regNumber, setRegNumber] = useState<string>('');
  const [businessRegImage_init, setBusinessRegImage_init] =
    useState<string>('');
  const [businessRegFile, setBusinessRegFile] = useState<File[]>([]);

  //상태변경 useState
  const [profileFilestatus, setProfileFilestatus] = useState<string>('');
  const [tagStatus, setTagStatus] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [captionModalShow, setCaptionModalShow] = useState(false);

  const script = document.createElement('script');
  script.src =
    '//dapi.kakao.com/v2/maps/sdk.js?appkey=84e1c459f31b318cab45a0a83e1d85ae&autoload=false&libraries=services';
  script.async = true;
  useEffect(() => {
    setCenterData();
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      window.kakao.maps.load(function () {
        setIsApiLoading(true);
      });
    });
  }, []);

  useEffect(() => {
    getLocation();
  }, [address1]);
  const setCenterData = async () => {
    const response = await postCenterView({
      params: { centerId: center.centerId },
    });

    const result = response;
    if (!result) return;
    {
      const { center } = result;
      if (center) setCenter(center);
      setSelectedTag_init(
        center.sportsTagIds.map((v: SportsTagType) => v.tagId) || []
      );
      setSelectedTag(
        center.sportsTagIds.map((v: SportsTagType) => v.tagId) || []
      );
      setProfileFiles_init(center.bannerImages || []);
      setLatitude(center.location.latitude);
      setLongitude(center.location.longitude);
      setCaptions_init(center.captions || []);
      setCaptions(center.captions || []);
      setownerName(center.ownerName);
      setOwnerPhone(center.ownerPhone);
      setRegNumber(center.regNumber);
      setBusinessRegImage_init(center?.businessRegImage?.mediumImage);
      setResultTerms(
        center.terms.reduce((acc: { [key: string]: boolean }, term: any) => {
          acc[term.termId] = term.isViewCkOk;
          return acc;
        }, {} as { [key: string]: boolean })
      );
      setValue('displayName', center.displayName || '');
      setValue('address1', center.location.fullAddress.split('|')[0] || '');
      setValue('subAddress', center.location.fullAddress.split('|')[1] || '');
      setValue('introduce', center.introduce.text || '');
      setValue('centerPhone', center.centerPhone || '');
    }
  };

  const onSubmit = async (data: any) => {
    if (!checkValidate()) {
      return;
    }
    setIsLoading(true);
    let bannerImages: { [key: string]: any } = {};
    if (profileFiles) {
      for (let i = 0; i < profileFiles.length; i++) {
        bannerImages[i + 1] = profileFiles[i].name;
      }
    }
    const deleteSportsTagIds = selectedTag_init.filter(
      (v) => !selectedTag.includes(v)
    );
    const deleteBannerImagesObject = deleteBannerImages.reduce(
      (acc: any, curr, index) => {
        acc[index + 1] = curr; // 1-based index
        return acc;
      },
      {}
    );
    const _captions = captions.map((item) => {
      const { iconImageUrl, ...rest } = item;
      return rest;
    });
    let deleteCaptions: any[] = [];
    if (center.captions) {
      deleteCaptions = center.captions
        .filter((caption) => {
          return !captions.some((v) => v.captionsNo === caption.captionsNo);
        })
        .map((caption) => caption.captionsNo);
    }
    const response = await postUpdateAcceptCenter({
      params: { centerId: center.centerId },
      data: {
        bannerImages,
        deleteBannerImages: deleteBannerImagesObject,
        displayName: data.displayName,
        fullAddress: data.address1 + '|' + data.subAddress,
        introduce: data.introduce,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        centerPhone: data.centerPhone,
        sportsTagIds: selectedTag,
        deleteSportsTagIds,
        captions: _captions,
        deleteCaptions,
        term_permissions: resultTerms,
      },
    });
    const result = response;
    if (!result) return;
    const { center: updatedCenter } = result;
    setCenter(updatedCenter);

    const { bannerImagesPresignedUrl } = result;
    const uploadTasks: any[] = [];
    //씬 썸네일 보내기 (ST00 + ST01)
    profileFiles.map((item: any, index: number) => {
      bannerImagesPresignedUrl[index] &&
        uploadTasks.push(uploadS3(bannerImagesPresignedUrl[index], item));
    });
    uploadTasks.length > 0 &&
      Promise.all(uploadTasks)
        .then(() => {
          window.location.reload();
          setIsEdit(false);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('An error occurred during the upload:', error);

          alert(UPLOAD_ALERT_TEXT);
          setIsLoading(false);
        });
    if (uploadTasks.length < 1) {
      window.location.reload();
      setIsEdit(false);
      setIsLoading(false);
    }
  };
  const addImage = (file: File, target: string) => {
    if (target === 'profile') {
      setProfileFiles((prev) => [...prev, file]); // `file`을 그대로 추가합니다.
    }
  };
  const deleteImage = (file: File | string, target: string) => {
    if (target === 'profile') {
      if (typeof file === 'string') {
        setDeleteBannerImages((prev) => [...prev, file]);
      } else {
        setProfileFiles((prev) => {
          return profileFiles.filter((v) => v !== file);
        });
      }
    }
  };
  // Kakao API
  const handleComplete = (data: any) => {
    setIsOpenPost(false);
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setAddress1(fullAddress);
    setValue('address1', fullAddress);
    getLocation();
  };

  const getLocation = async () => {
    if (!isApiLoading) {
      return;
    }
    const geocoder = new window.kakao.maps.services.Geocoder();

    var callback = function (result: any, status: any) {
      if (status === window.kakao.maps.services.Status.OK) {
        const { x: long, y: lat } = result[0].road_address;
        setLongitude(long);
        setLatitude(lat);
      }
    };
    trigger('location');
    geocoder.addressSearch(address1, callback);
  };
  const checkValidate = () => {
    if (
      profileFiles.length < 1 &&
      profileFiles_init.length === deleteBannerImages.length
    ) {
      setProfileFilestatus('false');
    }
    if (selectedTag.length < 1) {
      setTagStatus('false');
    }
    if (
      (profileFiles_init.length !== deleteBannerImages.length ||
        profileFiles.length > 0) &&
      selectedTag.length > 0
    ) {
      return true;
    }
    return false;
  };
  const deleteCaptions = (index: number) => {
    setCaptions(() => {
      return captions.filter((v, idx) => idx !== index);
    });
  };
  return (
    <>
      <Container>
        <Head>센터 정보</Head>
        <Margin margin='0 0 30px 0' />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            <InputName>센터명</InputName>
            {/* <Input
              id='displayName'
              placeholder='센터명을 입력하세요'
              aria-invalid={
                isDirty ? (errors.displayName ? 'true' : 'false') : undefined
              }
              {...register('displayName', {
                required: '센터명을 입력하세요.',
              })}
              defaultValue={center.displayName}
              readOnly={!isEdit}
            /> */}
            <Input
              name='displayName'
              placeholder='센터명을 입력하세요'
              control={control}
              rules={{
                required: '센터명을 입력하세요',
              }}
              readOnly={!isEdit}
            />
            {errors.displayName && (
              <Error role='alert'>
                {errors.displayName.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>센터주소</InputName>
            <Flex flexDirection='row' gap='12px'>
              <Input
                name='address1'
                placeholder='주소를 입력하세요'
                control={control}
                // aria-invalid={
                //   isDirty ? (errors.location ? 'true' : 'false') : undefined
                // }
                onClick={() => {
                  isEdit && setIsOpenPost(true);
                }}
                readOnly={true}
              />
              <AddressFindButton
                type='button'
                onClick={() => isEdit && setIsOpenPost(true)}
              >
                주소검색
              </AddressFindButton>
            </Flex>
            <Input
              name='subAddress'
              placeholder='상세주소'
              control={control}
              readOnly={!isEdit}
            />
            {errors.address && (
              <Error role='alert'>{errors.address.message?.toString()}</Error>
            )}
            {errors.subAddress && (
              <Error role='alert'>
                {errors.subAddress.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>센터소개</InputName>
            <TextArea
              id='introduce'
              placeholder='센터소개를 입력하세요'
              aria-invalid={
                isDirty ? (errors.introduce ? 'true' : 'false') : undefined
              }
              {...register('introduce', {
                required: '센터소개를 입력하세요.',
              })}
              readOnly={!isEdit}
            />
            {errors.introduce && (
              <Error role='alert'>{errors.introduce.message?.toString()}</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>센터 연락처</InputName>
            <Input
              name='centerPhone'
              placeholder='센터 연락처를 입력하세요'
              control={control}
              rules={{
                required: '센터 연락처를 입력하세요.',
              }}
              readOnly={!isEdit}
            />
            {errors.centerPhone && (
              <Error role='alert'>
                {errors.centerPhone.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>대표이미지 등록</InputName>
            <ImageContainer>
              {profileFiles_init.map((profileFile_init: ImageType, i) => {
                if (
                  profileFile_init.id &&
                  !deleteBannerImages.includes(profileFile_init.id)
                ) {
                  return (
                    <UploadImageContainer
                      key={
                        profileFile_init.id /* or another unique identifier */
                      }
                    >
                      <ImageWrapper>
                        <img src={profileFile_init.largeImage} alt='banner' />
                      </ImageWrapper>
                      {isEdit && (
                        <DeleteButton
                          onClick={() => {
                            const id = profileFile_init.id;
                            if (id)
                              setDeleteBannerImages((prev) => [...prev, id]);
                          }}
                        >
                          <Icon icon='X' width={9.8} height={9.8} />
                        </DeleteButton>
                      )}
                    </UploadImageContainer>
                  );
                }
                return null;
              })}

              {profileFiles.length > 0 &&
                profileFiles.map((file, index) => {
                  let imageUrl = '';
                  if (file instanceof File) {
                    imageUrl = URL.createObjectURL(file);
                  } else {
                    imageUrl = file;
                  }
                  return (
                    <UploadImageContainer key={index}>
                      <ImageWrapper>
                        <img src={imageUrl} />
                      </ImageWrapper>
                      {isEdit && (
                        <DeleteButton
                          onClick={() => deleteImage(file, 'profile')}
                        >
                          <Icon icon='X' width={9.8} height={9.8} />
                        </DeleteButton>
                      )}
                    </UploadImageContainer>
                  );
                })}
              <input
                id='profileImage'
                type='file'
                accept={allowedImageFormats}
                style={{ display: 'none' }}
                onChange={(e) =>
                  e.target.files && addImage(e.target.files[0], 'profile')
                }
              />
              {isEdit && (
                <label htmlFor='profileImage'>
                  <AddImage>
                    <Span>+</Span>
                    <Span>이미지 추가</Span>
                  </AddImage>
                </label>
              )}
            </ImageContainer>
            {profileFilestatus === 'false' && profileFiles.length < 1 && (
              <Error role='alert'>대표이미지를 등록하세요.</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>스포츠 태그</InputName>
            <TagContainer>
              <CenterTags
                isEdit={isEdit}
                selectedTag={selectedTag}
                setSelectedTag={setSelectedTag}
              />
            </TagContainer>

            {tagStatus === 'false' && selectedTag.length < 1 && (
              <Error role='alert'>태그를 선택하세요.</Error>
            )}
          </InputContainer>

          <InputContainer>
            <InputName>캡션</InputName>
            {captions.length > 0 || isEdit ? (
              <Captions
                captions={captions}
                setCaptions={setCaptions}
                isEdit={isEdit}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
              />
            ) : (
              <Span color='var(--c300)'>캡션이 없습니다</Span>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>대표자 성명</InputName>
            <Input
              name='ownerName'
              placeholder='대표자 성명을 입력하세요'
              control={control}
              // aria-invalid={
              //   isDirty ? (errors.ownerName ? 'true' : 'false') : undefined
              // }
              // {...register('ownerName', {
              //   required: '대표자 성명을 입력하세요.',
              // })}
              readOnly={true}
              defaultValue={center.ownerName}
            />
            {errors.ownerName && (
              <Error role='alert'>{errors.ownerName.message?.toString()}</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>대표자 연락처</InputName>
            <Input
              name='ownerPhone'
              placeholder='대표자 연락처를 입력하세요'
              control={control}
              // aria-invalid={
              //   isDirty ? (errors.ownerPhone ? 'true' : 'false') : undefined
              // }
              // {...register('ownerPhone', {
              //   required: '대표자 연락처를 입력하세요.',
              // })}
              readOnly={true}
              defaultValue={center.ownerPhone}
            />
            {errors.ownerPhone && (
              <Error role='alert'>
                {errors.ownerPhone.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>사업자등록번호</InputName>
            <Input
              name='regNumber'
              placeholder='사업자등록번호를 입력하세요'
              control={control}
              // aria-invalid={
              //   isDirty ? (errors.regNumber ? 'true' : 'false') : undefined
              // }
              // {...register('regNumber', {
              //   required: '사업자등록번호를 입력하세요.',
              // })}
              readOnly={true}
              defaultValue={center.regNumber}
            />
            {errors.regNumber && (
              <Error role='alert'>{errors.regNumber.message?.toString()}</Error>
            )}
          </InputContainer>
          <InputContainer>
            <Flex flexDirection='row' gap='9px'>
              <InputName>사업자등록증</InputName>
              <Flex flexDirection='row' gap='3px'>
                <Span color='var(--primary)' fontSize='1.4rem' fontWeight='600'>
                  등록완료
                </Span>
                <Icon icon='CheckBox_Active' width={16} height={16} />
              </Flex>
            </Flex>
          </InputContainer>

          <InputContainer>
            <>
              <TermsWrapper>
                <Terms
                  readOnly={!isEdit}
                  control={control}
                  errors={errors}
                  resultTerms={resultTerms}
                  setResultTerms={setResultTerms}
                />
              </TermsWrapper>
            </>
          </InputContainer>
          {profile &&
            profile.role === 'owner' &&
            (isEdit ? (
              <Flex gap='7px' width='100%'>
                <WhiteBorderButton
                  width='180px'
                  type='button'
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  취소하기
                </WhiteBorderButton>
                {isLoading ? (
                  <SubmitButton width='100%' color='#4c4c51' type='submit'>
                    저장중...
                  </SubmitButton>
                ) : (
                  <SubmitButton width='100%' type='submit'>
                    저장하기
                  </SubmitButton>
                )}
              </Flex>
            ) : (
              <SubmitButton
                type='button'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  window.scrollTo(0, 0);
                  setIsEdit(true);
                }}
              >
                수정하기
              </SubmitButton>
            ))}
        </Form>
      </Container>
      {isOpenPost && (
        <PostcodeWrapper>
          <Flex
            flexDirection='row'
            height='38px'
            padding='0 15px'
            justifyContents='right'
          >
            <IconWrapper>
              <Icon
                icon='X'
                width={10}
                height={10}
                onClick={() => setIsOpenPost(false)}
              />
            </IconWrapper>
          </Flex>
          <DaumPostcodeEmbed
            onComplete={handleComplete}
            // jsOptions={{ animation: true }}
            // onError={function (error: unknown): void {
            //   throw new Error("Function not implemented.");
            // }}
          />
        </PostcodeWrapper>
      )}
      <CaptionModal
        setCaptions={setCaptions}
        modalShow={captionModalShow}
        setModalShow={setCaptionModalShow}
      />
    </>
  );
}
export default EditCenterFormAfter;
