const Loading = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='15'
    viewBox='0 0 14 15'
    fill='none'
  >
    <g id='time/loading'>
      <path
        id='Icon'
        d='M7.00004 4V7.49971M7.00004 7.49971L9.33337 9.25M7.00004 7.49971C7.00004 7.49989 6.99989 7.4996 7.00004 7.49971ZM0.749819 8.95833C0.640937 8.48983 0.583374 8.00164 0.583374 7.5C0.583374 6.99836 0.640937 6.51017 0.749819 6.04167M5.83337 1.18914C6.2117 1.11964 6.60163 1.08333 7.00004 1.08333C10.5439 1.08333 13.4167 3.95617 13.4167 7.5C13.4167 11.0438 10.5439 13.9167 7.00004 13.9167C6.60163 13.9167 6.2117 13.8804 5.83337 13.8109M3.79171 1.94177C2.91649 2.44806 2.17195 3.15513 1.62108 4M1.62108 11C2.17195 11.8449 2.91649 12.5519 3.79171 13.0582'
        stroke='#D3D4DC'
        strokeLinecap='round'
      />
    </g>
  </svg>
);

export default Loading;
