// useVideoControl.js
import { useRef, useState } from 'react';

const useVideoControl = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const play = () => {
    const video = videoRef.current;
    setIsVideoPlaying(true);
    if (video) {
      video.play();
    }
  };

  const pause = () => {
    const video = videoRef.current;
    setIsVideoPlaying(false);
    if (video) {
      video.pause();
    }
  };

  const currentBarHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const { x, width } = rect;
    const selectedPoint = ((e.clientX - x) / width) * 100;
    if (videoRef.current) {
      videoRef.current.currentTime =
        (videoRef.current.duration * selectedPoint) / 100;
    }
  };

  return { videoRef, isVideoPlaying, play, pause, currentBarHandler };
};

export default useVideoControl;
