import styled from 'styled-components';
type videoType = {
  ratio: string;
};
export const Container = styled.div<videoType>`
  display: flex;
  border-radius: 14px;
  width: 350px;
  height: 311px;
  overflow: hidden;
  margin: 20px 0;
  flex-direction: ${({ ratio }) => (ratio === 'landscape' ? 'column' : 'row')};
`;
export const VideoWrapper = styled.div<videoType>`
  position: relative;
  width: ${({ ratio }) => (ratio === 'landscape' ? '350px' : '175px')};
  height: ${({ ratio }) => (ratio === 'landscape' ? '155.5px' : '311px')};
  flex-shrink: 0;
  > video {
    width: 100%;
    height: 100%;
  }
`;
export const ProgressBar = styled.div`
  position: relative;
  width: 296px;
  height: 5px;
  background-color: var(--c500);
  cursor: pointer;
`;

type CurrentBarType = {
  width: number;
};
export const CurrentBar = styled.div<CurrentBarType>`
  position: absolute;
  left: 0;
  max-width: 296px;
  width: ${({ width }) => (width ? `${width}%` : '0%')};
  height: 5px;
  background-color: var(--primary);
  cursor: pointer;
`;
export const ConvertButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  top: 0;
  left: 0;
  padding: 13px;
  cursor: pointer;
`;
export const ConvertButtonDiv = styled.div`
  width: 101px;
  height: 36px;
  border-radius: 18px;
  background: var(--c800);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`;
