import { useEffect, useState } from 'react';
import { Flex, Span, Container, Margin, Head } from 'styles/style';
import { useCenterStore } from 'store/auth';
import { UserTable } from 'components/UserTable';
import { ListInfoType, ProfileType } from 'constants/type';
import { getUserList } from 'apis/user';
const ManageCenterPage = () => {
    const { center } = useCenterStore();
    const [list, setList] = useState<ProfileType[]>([]);
    const [listInfo, setListInfo] = useState<ListInfoType>({
        count: 0,
        isLastOffset: false,
        limit: 0,
        offset: 0,
        totalCount: 0,
    });
    const [offset, setOffset] = useState<number>(0);

    useEffect(() => {
        setData();
    }, []);
    useEffect(() => {
        setData();
    }, [offset]);
    const setData = async () => {
        try {
            const { list, listInfo } = await getUserList({
                centerId: center.centerId,
                role: 'staff',
                offset,
            });
            if (list) setList(list);
            else setList([]);

            if (listInfo) setListInfo(listInfo);
        } catch (e) {
            console.error('setData error');
            setList([]);
        }
    };

    return (
        <>
            <Container>
                <Head>센터 스태프</Head>
                <Margin margin='30px 0 0' />
                <Flex justifyContents='space-between'>
                    <Span color='var(--c100)'>총 {listInfo.totalCount || 0}명</Span>
                </Flex>
                <UserTable
                    dataList={list}
                    dataInfo={listInfo}
                    type='staff'
                    setOffset={setOffset}
                    deleteOn={false}
                />
            </Container>
        </>
    );
};
export default ManageCenterPage;
