import { useLocation, useNavigate } from 'react-router-dom';
import { useCenterStore } from 'store/auth';
import { Container, Item } from './style';
import { postDeletePost, postDeleteReply } from 'apis/community';

type Type = {
  isMyPost: boolean;
  postId: string;
  replyId?: string;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuOpenId?: React.Dispatch<React.SetStateAction<string>>;
  editUrl?: string;
  top?: string;
};
export const ToggleMenu = ({
  isMyPost,
  postId,
  replyId,
  editUrl,
  top,
  setMenuOpenId,
  setIsEdit,
}: Type) => {
  const { center } = useCenterStore();
  const location = useLocation();
  const pathname = location.pathname;
  const page = pathname.split('/').pop();
  const navigate = useNavigate();
  const deletePost = async () => {
    const response = await postDeletePost({
      centerId: center.centerId,
      postId,
    });

    const result = response;
    if (!result) return;

    page === 'detail' ? navigate('/center/community') : navigate(0);
  };
  const deleteReply = async () => {
    if (!replyId) return;
    const response = await postDeleteReply({
      params: { postId },
      data: {
        centerId: center.centerId,
        replyId,
      },
    });
    const result = response;
    if (!result) return;

    navigate(0);
  };
  return (
    <>
      <Container top={top}>
        {isMyPost ? (
          <>
            <Item
              onClick={(e) => {
                e.stopPropagation();
                editUrl && navigate(editUrl);
                setMenuOpenId && setMenuOpenId('');
                setIsEdit && setIsEdit(true);
              }}
            >
              수정하기
            </Item>
            <Item
              color='var(--semantic-red)'
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm('정말 삭제하시겠습니까?')) {
                  replyId ? deleteReply() : deletePost();
                }
              }}
            >
              삭제하기
            </Item>
          </>
        ) : (
          <>
            <Item color='var(--semantic-red)'>신고하기</Item>
            <Item
              color='var(--semantic-red)'
              onClick={(e) => {
                e.stopPropagation();
                if (window.confirm('정말 삭제하시겠습니까?'))
                  replyId ? deleteReply() : deletePost();
              }}
            >
              삭제하기
            </Item>
          </>
        )}
      </Container>
    </>
  );
};
