import {
  Control,
  FieldValues,
  Path,
  RegisterOptions,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { InputWrapper } from './style';
type InputType<T extends FieldValues> = {
  name: Path<T>;
  defaultValue?: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'password' | 'checkbox';
  control: Control<T>;
  rules?: Omit<
    RegisterOptions<T>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  checked?: boolean;
  onChange?: (termId: number, isChecked: boolean) => void;
  onClick?: () => void;
  readOnly?: boolean;
};
const Input = <T extends FieldValues>({
  name,
  defaultValue,
  placeholder,
  type = 'text',
  control,
  rules,
  checked,
  onChange: propOnChange,
  onClick: propOnClick,
  readOnly,
}: InputType<T>) => {
  const {
    field: { value, onChange: controllerOnChange },
    fieldState,
  } = useController({ name, rules, control });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 만약 props로 넘어온 onChange가 있으면 그것을 사용
    if (propOnChange && type === 'checkbox') {
      propOnChange(Number(event.target.id), event.target.checked);
    } else {
      // 그렇지 않으면 useController에서 가져온 onChange를 사용
      controllerOnChange(event);
    }
  };
  return (
    <InputWrapper
      id={name}
      type={type}
      checked={type === 'checkbox' ? checked : undefined}
      onChange={handleChange}
      onClick={propOnClick}
      placeholder={placeholder}
      isHidden={type === 'checkbox'}
      value={value}
      defaultValue={defaultValue}
      readOnly={readOnly}
    />
  );
};

export default Input;
