import styled from 'styled-components';

export const Container = styled.div`
  width: 530px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 50px 40px;
  background: var(--grayscale-c-900, #24252d);
`;
export const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 2.4rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
`;
export const AddressFindButton = styled.button`
  display: inline-flex;
  padding: 16px 18px 15px 19px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  color: var(--c1100);
  font-weight: 600;
  width: 100px;
  height: 48px;
  font-size: 1.3rem;
`;
export const PostcodeWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 3px solid var(--c200);
  z-index: 9999;
`;
export const TagContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
type TagImageContainerType = {
  active: boolean;
};
export const TagImageContainer = styled.div<TagImageContainerType>`
  position: relative;
  width: 82px;
  height: 82px;
  border-radius: 100%;
  overflow: hidden;
  border: ${({ active }) => (active ? '1px solid white' : 'none')};
  cursor: pointer;
`;
export const TagName = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
`;
export const TagImage = styled.img`
  opacity: 50%;
`;
