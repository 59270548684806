import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Error } from 'styles/FormStyle';
import {
  Container,
  Background,
  Header,
  Title,
  RequiredButton,
  AddButton,
} from '../style';
import { Flex, Margin, Span } from 'styles/style';
import {
  Input,
  PrepareContainer,
  PrepareImageWrapper,
  PrepareRequiredBox,
  PrepareText,
} from 'styles/FormStyle';
import Icon from 'components/Icon';
import { CaptionType } from 'constants/type';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { usePreventScroll } from 'hooks/usePreventScroll';
import { postCaptionList } from 'apis/resource';
type CaptionModalType = {
  setCaptions: React.Dispatch<React.SetStateAction<any[]>>;
  modalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
};
export const CaptionModal = ({
  setCaptions,
  modalShow,
  setModalShow,
}: CaptionModalType) => {
  const [captionList, setCaptionList] = useState<CaptionType[]>([]);
  const [text, setText] = useState<string>('');
  const [activeCaption, setActiveCaption] = useState<{
    iconId: string;
    iconImageUrl: string;
  }>({
    iconId: '',
    iconImageUrl: '',
  });
  const [textCheck, setTextCheck] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { center } = useCenterStore();
  const { accessToken } = useAccessTokenStore();
  useEffect(() => {
    getcaptionList();
  }, []);
  usePreventScroll(modalShow);

  const getcaptionList = async () => {
    const response = await postCaptionList();
    const result = response;
    if (!result) return;

    const { captionsList } = result;
    setCaptionList(captionsList);
  };
  const handleClose = () => {
    setText('');
    setActiveCaption({ iconId: '', iconImageUrl: '' });
    setModalShow(false);
    setButtonClicked(false);
  };
  const addCaption = () => {
    setButtonClicked(true);
    if (text === '') {
      setTextCheck(false);
      return;
    }
    if (activeCaption.iconId === '') {
      return;
    }
    setCaptions((prev: Array<CaptionType>) => [
      ...prev,
      {
        text,
        iconId: activeCaption.iconId,
        iconImageUrl: activeCaption.iconImageUrl,
      },
    ]);
    handleClose();
  };
  return (
    <>
      {modalShow && (
        <Background onClick={() => handleClose()}>
          <Container onClick={(e) => e.stopPropagation()}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addCaption();
              }}
            >
              <Header>캡션 추가하기</Header>
              <Margin margin='0 0 40px 0' />
              <Title>미리보기</Title>
              <Margin margin='0 0 13px 0' />
              <PrepareContainer width='72px'>
                <PrepareImageWrapper
                  width='72px'
                  height='72px'
                  cursor='default'
                >
                  <img src={activeCaption.iconImageUrl || '/Prepare1.png'} />
                </PrepareImageWrapper>
                <PrepareText>{text !== '' ? text : '24시간'}</PrepareText>
              </PrepareContainer>
              <Margin margin='0 0 45px 0' />
              <Flex gap='20px'>
                <Flex gap='10px' flexDirection='column' flex={1}>
                  <Title>캡션 이름</Title>
                  <Input
                    placeholder='캡션 이름을 입력하세요.'
                    onChange={(e) => {
                      text !== '' && setTextCheck(true);
                      setText(e.target.value);
                    }}
                  />
                </Flex>
              </Flex>
              <Margin margin='10px 0 0 0' />
              {buttonClicked && text === '' && (
                <Error>캡션 이름을 입력하세요.</Error>
              )}
              <Margin margin='0 0 30px 0' />
              <Title>아이콘 선택</Title>
              <Margin margin='0 0 30px 0' />

              <Flex gap='20px' flexWrap='wrap'>
                {captionList.map((item, index) => {
                  return (
                    <PrepareImageWrapper
                      width='72px'
                      height='72px'
                      key={index}
                      active={item.iconId === activeCaption.iconId}
                      onClick={() =>
                        item.iconId === activeCaption.iconId
                          ? setActiveCaption({
                              iconId: '',
                              iconImageUrl: '',
                            })
                          : setActiveCaption({
                              iconId: item.iconId,
                              iconImageUrl: item.iconImageUrl,
                            })
                      }
                    >
                      <img src={item.iconImageUrl} />
                    </PrepareImageWrapper>
                  );
                })}
              </Flex>
              <Margin margin='20px 0 0 0' />
              {buttonClicked && activeCaption.iconId === '' && (
                <Error>캡션 아이콘을 선택하세요.</Error>
              )}
              <Margin margin='0 0 57px 0' />
              <Flex justifyContents='center'>
                <AddButton onClick={() => addCaption()}>추가하기</AddButton>
              </Flex>
            </form>
          </Container>
        </Background>
      )}
    </>
  );
};
