import { SceneType } from 'constants/type';
import Hls from 'hls.js';
import React, { useRef, useEffect } from 'react';
type VideoSnapshotType = {
  videoSrc: string;
  time: number;
  index: number;
  setThumbnailFile: (index: number, blob: Blob) => void;
};
function VideoSnapshot({
  videoSrc,
  time,
  index,
  setThumbnailFile,
}: VideoSnapshotType) {
  const videoRef = useRef<HTMLVideoElement>(null!);
  const canvasRef = useRef<HTMLCanvasElement>(null!);
  useEffect(() => {
    if (time === 0) {
      time = 1;
    }
    const video = videoRef.current;
    if (!video) return;
    if (Hls.isSupported() && videoSrc && videoSrc.endsWith('.m3u8')) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.currentTime = time / 1000;
      });
      video.addEventListener('seeked', function () {
        captureImage(video);
      });
    } else {
      video.currentTime = time / 1000;
      video.addEventListener('seeked', function () {
        captureImage(video);
      });
    }
  }, [videoSrc, time]);
  const captureImage = (video: HTMLVideoElement) => {
    if (!canvasRef) {
      return;
    }
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      getThumbnailFile();
    }
  };
  const getThumbnailFile = async () => {
    // canvas에서 데이터 URL 가져오기
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL('image/jpg');

    // 데이터 URL을 Blob으로 변환
    const blob = await (await fetch(dataURL)).blob();
    setThumbnailFile(index, blob);
  };
  return (
    <div>
      <video
        ref={videoRef}
        src={videoSrc}
        width='320'
        height='240'
        preload='metadata'
        style={{ display: 'none' }}
      ></video>
      <canvas ref={canvasRef} width='320' height='240'></canvas>
    </div>
  );
}

export default VideoSnapshot;
