import { postCommunityList } from 'apis/community';
import CommunityItem from 'components/CommunityItem';
import { Line } from 'components/FeedbackItem/style';
import Icon from 'components/Icon';
import { PostType } from 'constants/type';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCenterStore } from 'store/auth';
import styled from 'styled-components';
import { Flex, Span, Container, RelativeDiv } from 'styles/style';

const CommunityPage = () => {
  const [communityList, setCommunityList] = useState<PostType[]>([]);
  const { center } = useCenterStore();
  const [menuOpenId, setMenuOpenId] = useState<string>('');
  const [offset, setOffset] = useState(0); // 추가
  const navigate = useNavigate();

  //스크롤 처리
  const [totalCount, setTotalCount] = useState(0);
  const lastRef = useRef(null);
  const [observe, unobserve] = useIntersectionObserver(() => {
    setOffset((offset) => offset + 10);
  });

  useEffect(() => {
    getCommunityList();
  }, []);
  useEffect(() => {
    getCommunityList();
  }, [offset]);
  useEffect(() => {
    observe(lastRef.current);
    if (totalCount <= communityList.length || communityList.length === 0) {
      unobserve(lastRef.current);
    }
  }, [communityList]);
  const getCommunityList = async () => {
    const response = await postCommunityList({
      data: {
        centerId: center.centerId,
        limit: 10,
        offset,
        orderBy: 'desc',
      },
    });
    const result = response;
    if (!result) return;

    const { list, listInfo } = result;
    if (listInfo) {
      setTotalCount(listInfo.totalCount);
    }

    if (list?.length > 0 && offset === 0) {
      setCommunityList(list);
    } else if (list) {
      setCommunityList((prevList) => [...prevList, ...list]);
    }
  };
  return (
    <>
      <Container>
        <Flex
          margin='0 0 35px 0'
          justifyContents='space-between'
          alignItems='center'
        >
          <Span fontSize='18px' fontWeight='700' whiteSpace='nowrap'>
            커뮤니티
          </Span>
          <PostAddButton onClick={() => navigate('./add')}>
            <Icon icon='Plus' width={10} height={10} color='white' />
            <Span>새 글쓰기</Span>
          </PostAddButton>
        </Flex>
        {communityList.length > 0 ? (
          <>
            {communityList.map((data, i) => {
              return (
                <RelativeDiv key={data.postId + i}>
                  <CommunityItemWrapper
                    onClick={() =>
                      !data.isDeleted &&
                      navigate(`/center/community/detail?postId=${data.postId}`)
                    }
                  >
                    <CommunityItem
                      key={data.postId + 'item' + i}
                      {...data}
                      menuOpenId={menuOpenId}
                      setMenuOpenId={setMenuOpenId}
                    />
                  </CommunityItemWrapper>
                  <Line />
                </RelativeDiv>
              );
            })}
            <div ref={lastRef} style={{ height: '10px' }}></div>
          </>
        ) : (
          <Flex padding='30px 0 50px 0' justifyContents='center'>
            <Span fontWeight='bold' color='var(--c300)'>
              등록된 게시글이 없습니다.
            </Span>
          </Flex>
        )}
      </Container>
    </>
  );
};
export default CommunityPage;

const PostAddButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid white;
  padding: 8px 10px;
  border-radius: 6.5px;
  font-size: 1.5rem;
  cursor: pointer;
`;
const CommunityItemWrapper = styled.div`
  cursor: pointer;
`;
