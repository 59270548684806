import { useEffect, useState } from 'react';
import * as S from './style';
import * as CaptionsS from '../Captions/style';
import React from 'react';
import { postCaptionList } from 'apis/resource';
type CaptionIconBoxType = {
  openBoxId: number;
  changeIcon: (iconId: string, iconUrl: string, i: number) => void;
};
const CaptionIconBox = React.forwardRef<HTMLDivElement, CaptionIconBoxType>(
  ({ openBoxId, changeIcon }, ref) => {
    //auth
    const [captionImages, setCaptionImages] = useState<
      {
        iconId: string;
        iconImageUrl: string;
      }[]
    >([]);
    useEffect(() => {
      getCaptionImages();
    }, []);
    const getCaptionImages = async () => {
      try {
        const response = await postCaptionList();
        const result = response;
        const { captionsList } = result;
        setCaptionImages(captionsList);
      } catch (e) {
        console.error('getCaptionImages error');
        setCaptionImages([]);
      }
    };
    return (
      <S.Container ref={ref}>
        {captionImages.map((captionImage, index) => {
          return (
            <CaptionsS.CaptionIconWrapper
              key={index}
              onClick={() =>
                changeIcon(
                  captionImage.iconId,
                  captionImage.iconImageUrl,
                  openBoxId
                )
              }
            >
              <img
                src={captionImage?.iconImageUrl}
                alt='캡션 이미지'
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null; // 재귀적인 호출을 방지
                  target.src = captionImages[0]?.iconImageUrl;
                }}
              />
            </CaptionsS.CaptionIconWrapper>
          );
        })}
      </S.Container>
    );
  }
);
export default CaptionIconBox;
