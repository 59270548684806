import { useForm } from 'react-hook-form';
import { Flex, Margin, Span } from 'styles/style';
import {
  Form,
  InputContainer,
  InputName,
  Input,
  TextArea,
  UploadImageContainer,
  ImageWrapper,
  DeleteButton,
  AddImage,
  PrepareContainer,
  PrepareImageWrapper,
  PrepareText,
  PrepareRequiredBox,
  Error,
} from 'styles/FormStyle';
import {
  Container,
  Title,
  SubmitButton,
  FormWrapper,
  InputContainer_noW100,
  PrepareCircle,
  OverlayX,
  W300,
  CheckBoxContainer,
} from './style';
import Icon from 'components/Icon';
import { useEffect, useState } from 'react';
import PreviewScreen from 'components/PreviewScreen';
import MainVideoInput from 'components/MainVideoInput';
import Loading from 'components/Loading';
import { PrepareModal } from 'components/Modals/PrepareModal';
import { PreperationType, SceneType, SessionFormType } from 'constants/type';
import { useCenterStore, useAccessTokenStore } from 'store/auth';
import { uploadS3 } from 'constants/common';
import { useNavigate } from 'react-router-dom';
import { CheckBoxInput } from 'styles/LoginStyles';
import { allowedImageFormats } from 'constants/common';
import { UPLOAD_ALERT_TEXT } from 'constants/alertText';
import { postCreateSession, postSessionComplete } from 'apis/session';
function AddSessionForm() {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    setError,
    formState: { isDirty, errors, dirtyFields },
  } = useForm<SessionFormType>();
  const navigate = useNavigate();
  const { center } = useCenterStore();
  const [mainVideo, setMainVideo] = useState<File | null>(null);
  const [feedbackVideo, setFeedbackVideo] = useState<File | null>(null);
  const [sceneList, setSceneList] = useState<SceneType[]>([]);
  const [displayImageFile, setDisplayImageFile] = useState<File | null>();
  const [displayImage, setDisplayImage] = useState<string>('');
  const [selectedPreperationList, setSelectedPreperationList] = useState<
    PreperationType[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [authority, setAuthority] = useState<'public' | 'member_only'>(
    'public'
  );
  //메인 비디오 duration
  const [mainVideoDuration, setMainVideoDuration] = useState(0);
  const [mainVideoRatio, setMainVideoRatio] = useState<
    'landscape' | 'portrait'
  >('landscape');
  const [mainVideoSize, setMainVideoSize] = useState({ width: 0, height: 0 });

  const handleShow = () => setModalShow(true);
  const watchAllFields = watch();
  const onSubmit = async (data: any) => {
    if (!mainVideo || !displayImageFile) {
      return;
    }
    const scenes = sceneList
      .sort((a, b) => a.sceneStartAt - b.sceneStartAt)
      .map((item, index) => {
        const { thumbnailFile, ...rest } = item;
        rest.thumbnailUrl = `thumbnail${index}.jpeg`;
        rest.additionalStreamingRatio = mainVideoRatio;
        return rest;
      });
    const sessionImages = displayImageFile.name.toLowerCase();
    const { title, introduceText, introducesTitle, introducesContext } = data;
    const response = await postCreateSession({
      hlsStreamingFile: mainVideo.name,
      scenesList: scenes,
      sessionVo: {
        centerId: center.centerId,
        title: title,
        introduceText,
        introducesTitle,
        introducesContext,
        preperationIds: selectedPreperationList,
        streamingDuration: mainVideoDuration,
        streamingRatio: mainVideoRatio,
        sessionImages: sessionImages,
        authority,
        metadata: {
          originalWidth: mainVideoSize.width,
          originalHeight: mainVideoSize.height,
        },
        isFliped: false,
      },
      coachingFile: feedbackVideo?.name,
    });
    const result = response;
    if (!result) return;
    const {
      scenesThumbnailUrl,
      presignedUploadUrl,
      coachingPresignedUrl,
      sessionPresignedUrl,
      sessionId,
    } = result;
    navigate(`/center/session/detail?sessionId=${sessionId}`);
    //이후 동작
    const uploadTasks: any[] = [];
    //씬 썸네일 보내기 (ST00 + ST01)
    [...sceneList].map((item, index) => {
      const { thumbnailFile } = item;
      if (thumbnailFile && scenesThumbnailUrl[index]) {
        uploadTasks.push(uploadS3(scenesThumbnailUrl[index], thumbnailFile));
      }
    });
    //메인 영상 보내기
    if (mainVideo && presignedUploadUrl) {
      uploadTasks.push(uploadS3(presignedUploadUrl, mainVideo));
    }
    //따라하기 영상 보내기
    if (feedbackVideo && coachingPresignedUrl) {
      uploadTasks.push(uploadS3(coachingPresignedUrl, feedbackVideo));
    }
    //세션 대표 이미지 보내기
    if (displayImageFile && sessionPresignedUrl) {
      uploadTasks.push(uploadS3(sessionPresignedUrl, displayImageFile));
    }
    setIsLoading(true);
    Promise.all(uploadTasks)
      .then(() => {
        setIsLoading(false);
        sendComplete(sessionId);
      })
      .catch((error) => {
        console.error('An error occurred during the upload:', error);
        setIsLoading(false);
        alert(UPLOAD_ALERT_TEXT);
      });
  };
  const sendComplete = async (sessionId: string) => {
    await postSessionComplete({
      sessionId,
      centerId: center.centerId,
    });
  };
  useEffect(() => {
    dirtyFields['displayImageFile'] && trigger('displayImageFile');
  }, [displayImageFile]);
  useEffect(() => {
    if (selectedPreperationList.length > 0) {
      setError('prepare', { type: 'manual', message: '' });
    }
    dirtyFields['prepare'] && trigger('prepare');
  }, [selectedPreperationList]);
  const addDisplayImageFile = (fileBlob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    setDisplayImageFile(fileBlob);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setDisplayImage(reader.result);
          setValue('displayImageFile', fileBlob, { shouldDirty: true });
          setError('displayImageFile', { type: 'manual', message: '' });
        } else {
          reject('Image read result is not a string');
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };
    });
  };
  const deletedisplayImageFile = () => {
    setDisplayImage('');
  };
  const deletePrepare = (index: number) => {
    setSelectedPreperationList(() => {
      return selectedPreperationList.filter((v, idx) => idx !== index);
    });
  };
  return (
    <>
      <Container>
        <FormWrapper>
          {isLoading ? <Loading num={1} /> : null}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              flexDirection='row'
              justifyContents='space-between'
              alignItems='center'
              margin='0 0 23px 0'
              width='100%'
            >
              <Title>새로운 세션 추가하기</Title>
              <SubmitButton type='submit'>등록하기</SubmitButton>
            </Flex>
            <InputContainer>
              <InputName>세션명</InputName>
              <Input
                id='title'
                placeholder='세션 제목을 입력하세요. ex) 케틀벨 스윙으로 부하를 주는 운동법'
                aria-invalid={
                  isDirty ? (errors.title ? 'true' : 'false') : undefined
                }
                {...register('title', {
                  required: '세션명을 입력하세요.',
                })}
              />
              {errors.title && (
                <Error role='alert'>{errors.title.message?.toString()}</Error>
              )}
            </InputContainer>

            <InputContainer>
              <InputName>세션 소개</InputName>
              <TextArea
                id='introduceText'
                placeholder='세션 소개를 자유롭게 입력하세요. 최대 1,000자 까지 입력할 수 있습니다.'
                aria-invalid={
                  isDirty
                    ? errors.introduceText
                      ? 'true'
                      : 'false'
                    : undefined
                }
                {...register('introduceText', {
                  required: '세션 소개를 입력하세요.',
                })}
              />
              {errors.introduceText && (
                <Error role='alert'>
                  {errors.introduceText.message?.toString()}
                </Error>
              )}
            </InputContainer>
            <InputContainer>
              <InputName>공개 범위</InputName>
              <Flex gap='15px'>
                <CheckBoxContainer onClick={() => setAuthority('public')}>
                  <CheckBoxInput id='public' type='checkbox' />
                  <label htmlFor='public'>
                    {authority === 'public' ? (
                      <Icon width={22} height={22} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={22} height={22} icon='CheckBox' />
                    )}
                  </label>
                  <Span color='var(--c100)' fontSize='14px'>
                    전체공개
                  </Span>
                </CheckBoxContainer>

                <CheckBoxContainer onClick={() => setAuthority('member_only')}>
                  <CheckBoxInput id='member_only' type='checkbox' />
                  <label htmlFor='member_only'>
                    {authority === 'member_only' ? (
                      <Icon width={22} height={22} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={22} height={22} icon='CheckBox' />
                    )}
                  </label>
                  <Span color='var(--c100)' fontSize='14px'>
                    회원공개
                  </Span>
                </CheckBoxContainer>
              </Flex>
            </InputContainer>
            <Flex flexDirection='row' gap='30px' width='100%'>
              <InputContainer_noW100>
                <InputName>세션 대표 이미지</InputName>
                <Flex flexDirection='row' gap='10px'>
                  <input
                    id='displayImageFile'
                    type='file'
                    accept={allowedImageFormats}
                    style={{ display: 'none' }}
                    aria-invalid={
                      isDirty
                        ? errors.displayImageFile
                          ? 'true'
                          : 'false'
                        : undefined
                    }
                    {...register('displayImageFile', {
                      validate: {
                        isVerified: () =>
                          displayImage === ''
                            ? '대표이미지를 등록하세요.'
                            : true,
                      },
                    })}
                    onChange={(e) =>
                      e.target.files && addDisplayImageFile(e.target.files[0])
                    }
                  />
                  {displayImage !== '' ? (
                    <UploadImageContainer>
                      <ImageWrapper>
                        <img src={displayImage} />
                      </ImageWrapper>
                      <DeleteButton onClick={() => deletedisplayImageFile()}>
                        <Icon icon='X' width={9.8} height={9.8} />
                      </DeleteButton>
                    </UploadImageContainer>
                  ) : (
                    <label htmlFor='displayImageFile'>
                      <AddImage>
                        <Span>+</Span>
                        <Span>이미지 추가</Span>
                      </AddImage>
                    </label>
                  )}
                </Flex>
                {errors.displayImageFile && (
                  <Error role='alert'>
                    {errors.displayImageFile.message?.toString()}
                  </Error>
                )}
              </InputContainer_noW100>

              <InputContainer_noW100>
                <InputName>준비물</InputName>
                <Flex flexDirection='row' gap='10px' alignItems='flex-start'>
                  <input
                    id='prepare'
                    type='file'
                    accept={allowedImageFormats}
                    style={{ display: 'none' }}
                    aria-invalid={
                      isDirty ? (errors.prepare ? 'true' : 'false') : undefined
                    }
                    {...register('prepare', {
                      validate: {
                        isVerified: () =>
                          selectedPreperationList.length < 1
                            ? '준비물을 추가해주세요'
                            : true,
                      },
                    })}
                  />
                  <PrepareCircle onClick={() => handleShow()}>
                    <Span>+ 준비물</Span>
                    <Span>추가</Span>
                  </PrepareCircle>
                  <W300>
                    {selectedPreperationList.length > 0 &&
                      selectedPreperationList.map((item, index) => {
                        return (
                          <PrepareContainer key={index}>
                            <PrepareImageWrapper
                              width='82px'
                              height='82px'
                              backgroundColor='#2c2d34'
                              cursor='default'
                            >
                              <img src={item.iconUrl} />

                              <OverlayX onClick={() => deletePrepare(index)}>
                                <Icon icon='WhiteX' width={15} height={15} />
                              </OverlayX>
                              {item.isNecessary && (
                                <PrepareRequiredBox>필수</PrepareRequiredBox>
                              )}
                            </PrepareImageWrapper>
                            <PrepareText>{item.title}</PrepareText>
                          </PrepareContainer>
                        );
                      })}
                  </W300>
                </Flex>
                {errors.prepare && (
                  <Error role='alert'>
                    {errors.prepare.message?.toString()}
                  </Error>
                )}
              </InputContainer_noW100>
            </Flex>
            <InputContainer>
              <InputName>설명 제목</InputName>
              <Input
                id='introducesTitle'
                placeholder='설명 제목을 입력해 주세요 ex) 시작하기 전 알아야 할 것들'
                aria-invalid={
                  isDirty
                    ? errors.introducesTitle
                      ? 'true'
                      : 'false'
                    : undefined
                }
                {...register('introducesTitle', {
                  required: '설명 제목을 입력하세요.',
                })}
              />
              {errors.introducesTitle && (
                <Error role='alert'>
                  {errors.introducesTitle.message?.toString()}
                </Error>
              )}
            </InputContainer>
            <InputContainer>
              <InputName>설명 내용</InputName>
              <TextArea
                id='introducesContext'
                placeholder='설명을 자유롭게 입력하세요. 최대 1,500자 까지 입력할 수 있습니다.'
                aria-invalid={
                  isDirty
                    ? errors.introducesContext
                      ? 'true'
                      : 'false'
                    : undefined
                }
                {...register('introducesContext', {
                  required: '설명 내용를 입력하세요.',
                })}
              />
              {errors.introducesContext && (
                <Error role='alert'>
                  {errors.introducesContext.message?.toString()}
                </Error>
              )}
            </InputContainer>

            {/* 동영상 추가 */}
            <MainVideoInput
              setIsLoading={setIsLoading}
              mainVideo={mainVideo}
              setMainVideo={setMainVideo}
              feedbackVideo={feedbackVideo}
              setFeedbackVideo={setFeedbackVideo}
              mainVideoDuration={mainVideoDuration}
              setMainVideoDuration={setMainVideoDuration}
              mainVideoRatio={mainVideoRatio}
              setMainVideoRatio={setMainVideoRatio}
              sceneList={sceneList}
              setSceneList={setSceneList}
              setMainVideoSize={setMainVideoSize}
              errors={errors}
              register={register}
            />
          </Form>
        </FormWrapper>
        <PreviewScreen
          formData={watchAllFields}
          displayImage={displayImage}
          mainVideoDuration={mainVideoDuration}
          selectedPreperationList={selectedPreperationList}
        />
        <Margin margin='0 -5px 0 0' />
      </Container>
      <PrepareModal
        setSelectedPreperationList={setSelectedPreperationList}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
}
export default AddSessionForm;
