// hooks/usePreventScroll.ts

import { useEffect } from 'react';

function preventScroll(event: any) {
  event.preventDefault();
}

export const usePreventScroll = (isActive: boolean) => {
  useEffect(() => {
    if (isActive) {
      window.addEventListener('wheel', preventScroll, { passive: false });
    } else {
      window.removeEventListener('wheel', preventScroll);
    }
    return () => {
      window.removeEventListener('wheel', preventScroll);
    };
  }, [isActive]);
};
