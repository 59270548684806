import Icon from 'components/Icon';
import { ProfileImage } from 'components/Navigation/style';
import { timeAgo } from 'constants/common';
import { FeedbackType } from 'constants/type';
import { useNavigate } from 'react-router-dom';
import { CircleImage, Flex, PremiumBadge, Span } from 'styles/style';
import {
  AddFeedbackButton,
  ContentContainer,
  ContentWrapper,
  ItemContainer,
  MainContainer,
  ThumbImageWrapper,
  TopContainer,
} from './style';
import Img from 'components/Img';
type FeedbackItemType = {
  feedback: FeedbackType;
};
const FeedbackItem = ({ feedback }: FeedbackItemType) => {
  const navigate = useNavigate();
  return (
    <>
      <ItemContainer>
        <CircleImage>
          <img
            src={
              feedback.requestedBy?.profileImage?.thumbnailImage ||
              '/defaultUserImage.png'
            }
            alt='프로필 이미지'
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null; // 재귀적인 호출을 방지
              target.src = '/defaultUserImage.png';
            }}
          />
        </CircleImage>
        <Flex flexDirection='column' gap='10px'>
          <TopContainer>
            <Span fontSize='14px' color='white'>
              {feedback.requestedBy?.nickname}
            </Span>
            <Span fontSize='12px' color='var(--c500)'>
              {timeAgo(feedback.createdAt)}
            </Span>
          </TopContainer>
          <MainContainer>
            <ThumbImageWrapper>
              {feedback.feedbackVideo.thumbnailImage?.mediumImage && (
                <Img
                  src={feedback.feedbackVideo.thumbnailImage?.mediumImage}
                  alt='요청 영상 썸네일 이미지'
                />
              )}
            </ThumbImageWrapper>
            <ContentContainer>
              <PremiumBadge>Premium</PremiumBadge>
              <ContentWrapper
                onClick={() => navigate('/center/feedback/detail')}
              >
                {feedback.feedbackContent}
              </ContentWrapper>
              {feedback.response ? (
                <Flex alignItems='center' gap='3px'>
                  <Icon
                    icon='ToggleCheckButton_Active'
                    width={16}
                    height={16}
                  />
                  <Span fontSize='14px' color='var(--primary)'>
                    응답완료
                  </Span>
                </Flex>
              ) : (
                <AddFeedbackButton
                  onClick={() => navigate('/center/feedback/detail')}
                >
                  <Span>피드백 남기기</Span>
                  <Icon icon='RightArrow' />
                </AddFeedbackButton>
              )}
            </ContentContainer>
          </MainContainer>
        </Flex>
      </ItemContainer>
    </>
  );
};
export default FeedbackItem;
