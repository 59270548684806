import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px;
  border-radius: 7px;
  margin-bottom: 15px;
`;
