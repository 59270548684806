import './App.css';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import DefaultLayout from './components/DefaultLayout';
import PwdResetForm from './components/PwdResetForm';
import LoginPage from './pages/LoginPage';
import DashBoardPage from './pages/DashBoardPage';
import SessionPage from './pages/SessionPage';
import ManageUserPage from 'pages/ManageUserPage';
import CommunityPage from './pages/CommunityPage';
import AlarmPage from './pages/AlarmPage';
import ProfilePage from './pages/ProfilePage';
import PayPage from './pages/PayPage';
import TermsPage from './pages/TermsPage';
import NoCenter from 'components/NoCenter';
import KaKaoMap from 'components/KaKaoMap';
import SignUpPage from 'pages/SignupPage';
import AddSessionPage from 'pages/AddSessionPage';
import './firebase.tsx'; //Analytics 사용
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import LoginLayout from 'components/LoginLayout';
import FeedbackPage from 'pages/FeedbackPage';
import DetailFeedbackPage from 'pages/DetailFeedbackPage';
import EditCenterForm from 'components/EditCenterForm';
import { DetailCommunityPage } from 'pages/DetailCommunityPage';
import ManageCenterPage from 'pages/ManageStaffPage';
import DetailSessionPage from 'pages/DetailSessionPage';
import { AddCommunityPage } from 'pages/AddCommunityPage';
import { EditCommunityPage } from 'pages/EditCommunityPage';
import EditSessionPage from 'pages/EditSessionPage';
import MemberListPage from 'pages/MemberListPage';
import StaffListPage from 'pages/StaffListPage';
import ManageStaffPage from 'pages/ManageStaffPage';
import { Term1 } from 'pages/Term1';
import { Term2 } from 'pages/Term2';
import { CenterProfilePage } from 'pages/CenterProfilePage';
import ApplyCenterPage from 'pages/ApplyCenterPage';
import EditCenterPage from 'pages/EditCenterPage';
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorFallback from 'components/ErrorFallback';
import PendingView from 'components/PendingView';
import { Term3 } from 'pages/Term3';
function UnauthenticatedRoutes() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/pwdReset' element={<PwdResetForm />} />
      </Route>
    </Routes>
  );
}

function NoCenterRoutes() {
  return (
    <Routes>
      <Route path='/applyCenter' element={<ApplyCenterPage />} />
      <Route path='/noCenter' element={<NoCenter />} />
      <Route path='*' element={<Navigate to='/noCenter' />} />
    </Routes>
  );
}
function PendingStaffRoutes() {
  return (
    <Routes>
      <Route path='/pending' element={<PendingView />} />
      <Route path='*' element={<Navigate to='/pending' />} />
    </Routes>
  );
}
function PendingCenterRoutes() {
  return (
    <Routes>
      <Route path='/editCenter' element={<EditCenterPage />} />
      <Route path='*' element={<Navigate to='/editCenter' />} />
    </Routes>
  );
}

function ActivatedCenterRoutes() {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path='/kakao' element={<KaKaoMap />} />

        <Route path='/center/dashboard' element={<DashBoardPage />} />
        <Route path='/center/user/list' element={<MemberListPage />} />
        <Route path='/center/user/manage' element={<ManageUserPage />} />
        <Route path='/center/staff/profile' element={<CenterProfilePage />} />
        <Route path='/center/staff/list' element={<StaffListPage />} />
        <Route path='/center/staff/manage' element={<ManageStaffPage />} />
        <Route path='/center/session' element={<SessionPage />} />
        <Route path='/center/session/add' element={<AddSessionPage />} />
        <Route path='/center/session/detail' element={<DetailSessionPage />} />
        <Route path='/center/session/edit' element={<EditSessionPage />} />

        <Route path='/center/feedback' element={<FeedbackPage />} />
        <Route
          path='/center/feedback/detail'
          element={<DetailFeedbackPage />}
        />
        <Route path='/center/community' element={<CommunityPage />} />
        <Route path='/center/community/add' element={<AddCommunityPage />} />
        <Route path='/center/community/edit' element={<EditCommunityPage />} />
        <Route
          path='/center/community/detail'
          element={<DetailCommunityPage />}
        />
        <Route path='/center/alarm' element={<AlarmPage />} />
        <Route path='/setting/profile' element={<ProfilePage />} />
        <Route path='/setting/pay' element={<PayPage />} />
      </Route>
      <Route path='*' element={<Navigate to='/center/dashboard' />} />
    </Routes>
  );
}
function RoutingComponent() {
  //auth
  const { accessToken } = useAccessTokenStore();
  const { profile } = useProfileStore();

  const { center } = useCenterStore();
  const navigate = useNavigate();
  useEffect(() => {
    if (accessToken === '') {
      navigate('/login');
    }
  }, [accessToken]);

  if (!accessToken) return <UnauthenticatedRoutes />;

  if (
    !profile?.userKey ||
    center?.activateState === 'cancelled' ||
    profile.role === 'guest'
  )
    return <NoCenterRoutes />;
  if (
    profile?.userKey &&
    profile.role === 'staff' &&
    profile.permissionState !== 'activated'
  ) {
    return <PendingStaffRoutes />;
  }
  if (
    center?.activateState !== 'activated' ||
    profile?.permissionState !== 'activated'
  )
    return <PendingCenterRoutes />;

  return <ActivatedCenterRoutes />;
}

function App() {
  return (
    <ErrorBoundary>
      <GlobalStyle />
      <Router>
        <Routes>
          {/* 모두 접근 가능 */}
          <Route path='/setting/terms/provision' element={<Term1 />} />
          <Route path='/setting/terms/privacy' element={<Term2 />} />
          <Route path='/setting/terms/marketing' element={<Term3 />} />

          {/* 기존의 RoutingComponent 로직 */}
          <Route path='*' element={<RoutingComponent />} />
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
