import { useForm } from 'react-hook-form';
import { Flex, Margin, Span } from 'styles/style';
import {
  Form,
  InputContainer,
  InputName,
  Input,
  TextArea,
  UploadImageContainer,
  ImageWrapper,
  DeleteButton,
  AddImage,
  PrepareContainer,
  PrepareImageWrapper,
  PrepareText,
  PrepareRequiredBox,
  Error,
} from 'styles/FormStyle';
import {
  Container,
  Title,
  SubmitButton,
  FormWrapper,
  InputContainer_noW100,
  PrepareCircle,
  OverlayX,
  W300,
  CheckBoxContainer,
} from './style';
import { useLocation } from 'react-router-dom';
import Icon from 'components/Icon';
import { useEffect, useState } from 'react';
import PreviewScreen from 'components/PreviewScreen';
import Loading from 'components/Loading';
import { PrepareModal } from 'components/Modals/PrepareModal';
import {
  PreperationType,
  SceneType,
  SessionFormType,
  SessionType,
  VideoType,
} from 'constants/type';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { allowedImageFormats, uploadS3 } from 'constants/common';
import { useNavigate } from 'react-router-dom';
import { CheckBoxInput } from 'styles/LoginStyles';
import MainVideoInputDetail from 'components/MainVideoInputDetail';
import { SAVE_ALERT_TEXT, UPLOAD_ALERT_TEXT } from 'constants/alertText';
import {
  postSession,
  postSessionComplete,
  postUpdateSession,
} from 'apis/session';
function EditSessionForm() {
  const [sessionView, setSessionView] = useState<SessionType>();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    setError,
    getValues,
    formState: { isSubmitting, isDirty, errors, dirtyFields },
  } = useForm<SessionFormType>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('sessionId');
  const { accessToken } = useAccessTokenStore();
  const { center } = useCenterStore();
  const [sceneList, setSceneList] = useState<SceneType[]>([]);
  const [displayImageFile, setDisplayImageFile] = useState<File | null>();
  const [displayImage, setDisplayImage] = useState<string>('');
  const [selectedPreperationList, setSelectedPreperationList] = useState<
    PreperationType[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [authority, setAuthority] = useState<'public' | 'member_only'>(
    'public'
  );
  //메인 비디오 duration
  const [mainVideo, setMainVideo] = useState<VideoType>();
  const [isEdit, setIsEdit] = useState<boolean>(true);
  //삭제 리스트는 sessionView와 실제값 비교

  //값 넣기
  useEffect(() => {
    getSessionInfo();
  }, []);
  const getSessionInfo = async () => {
    if (!sessionId) return;
    const response = await postSession({
      params: {
        sessionId,
      },
      data: {
        centerId: center.centerId,
      },
    });
    const result = response;
    if (!result) return;

    if (result === null) {
      navigate(-1);
    }
    const { sessionView } = result;
    const { preperationList, scenes, authority } = sessionView;

    setValue('title', sessionView.title);
    setValue('introduceText', sessionView.introduceText);
    setValue('authority', sessionView.authority);
    setValue(
      'introducesTitle',
      sessionView.introduces ? sessionView.introduces[0]?.title : ''
    );
    setValue(
      'introducesContext',
      sessionView.introduces ? sessionView.introduces[0]?.context : ''
    );
    setAuthority(sessionView.authority);
    setSelectedPreperationList(sessionView.preperationList);
    setDisplayImage(sessionView.displayImage.mediumImage);
    setMainVideo(sessionView.video);
    const newScenes = sessionView.scenes.map((item: any) => {
      item.thumbnailUrlChange = false;
      return item;
    });
    setSceneList(newScenes);
    setSessionView(sessionView);
  };

  const handleShow = () => setModalShow(true);
  const watchAllFields = watch();
  const onSubmit = async (data: any) => {
    if (!sessionView) {
      alert(SAVE_ALERT_TEXT);
      return;
    }
    if (!sessionId) return;
    const scenes = sceneList
      .sort((a, b) => a.sceneStartAt - b.sceneStartAt)
      .map((item, index) => {
        const {
          thumbnailFile,
          additionalStreamingStatus,
          additionalVideo,
          sessionId,
          ...rest
        } = item;
        if (additionalVideo) {
          rest.additionalStreamingDuration = additionalVideo.duration;
        }
        if (rest.thumbnailUrlChange) {
          rest.thumbnailUrl = `thumbnail${index}.jpeg`;
        }
        return rest;
      });
    const { title, introduceText, introducesTitle, introducesContext } = data;

    const deletePreperationList = sessionView.preperationList
      .filter((v: PreperationType) => !selectedPreperationList.includes(v))
      .map((v: PreperationType) => ({ iconNo: v.iconNo }));
    const deleteScenes = sessionView.scenes
      .filter(
        (v: SceneType) =>
          !sceneList.some((scene) => scene.sceneId === v.sceneId)
      )
      .map((v: SceneType) => ({ sceneId: v.sceneId }));
    const response = await postUpdateSession({
      params: { sessionId },
      data: {
        scenesList: scenes,
        sessionVo: {
          centerId: center.centerId,
          title: title,
          introduceText,
          introducesTitle,
          introducesContext,
          preperationIds: selectedPreperationList,
          deletePreperationList,
          sessionImages: displayImageFile ? 'displayImageFile1.jpeg' : null,
          deleteSessionImages: displayImageFile && sessionView.displayImage.id,
          authority,
          deleteScenes,
          isFliped: false,
          originalWidth: sessionView.video?.metadata?.originalWidth,
          originalHeight: sessionView.video?.metadata?.originalHeight,
        },
      },
    });

    const result = response;
    if (!result) return;

    const {
      scenesThumbnailUrl,
      sessionPresignedUrl,
      sessionId: _sessionId,
    } = result;
    const uploadTasks: any[] = [];
    let responseThumbnailIndex = 0;
    [...sceneList].map((scene, index) => {
      const { thumbnailFile } = scene;
      if (thumbnailFile && scene.thumbnailUrlChange) {
        uploadTasks.push(
          uploadS3(scenesThumbnailUrl[responseThumbnailIndex], thumbnailFile)
        );
        responseThumbnailIndex++;
      }
    });
    //세션 대표 이미지 보내기
    if (displayImageFile && sessionPresignedUrl) {
      uploadTasks.push(uploadS3(sessionPresignedUrl, displayImageFile));
    }
    setIsLoading(true);
    Promise.all(uploadTasks)
      .then(() => {
        setIsLoading(false);
        sendComplete(_sessionId);
        navigate(`/center/session/detail?sessionId=${sessionId}`);
      })
      .catch((error) => {
        console.error('An error occurred during the upload:', error);
        setIsLoading(false);
        alert(UPLOAD_ALERT_TEXT);
        navigate(`/center/session/detail?sessionId=${sessionId}`);
      });
  };
  const sendComplete = async (sessionId: string) => {
    await postSessionComplete({
      sessionId,
      centerId: center.centerId,
    });
  };
  useEffect(() => {
    dirtyFields['displayImageFile'] && trigger('displayImageFile');
  }, [displayImageFile]);
  useEffect(() => {
    if (selectedPreperationList.length > 0) {
      setError('prepare', { type: 'manual', message: '' });
    }
    dirtyFields['prepare'] && trigger('prepare');
  }, [selectedPreperationList]);
  const addDisplayImageFile = (fileBlob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    setDisplayImageFile(fileBlob);
    return new Promise((resolve, reject) => {
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setDisplayImage(reader.result);
          setValue('displayImageFile', fileBlob, { shouldDirty: true });
          setError('displayImageFile', { type: 'manual', message: '' });
        } else {
          reject('Image read result is not a string');
        }
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };
    });
  };
  const deletedisplayImageFile = () => {
    setDisplayImage('');
  };
  const deletePrepare = (iconNo: string) => {
    const newArray = selectedPreperationList.filter(
      (preperation: PreperationType) => preperation.iconNo !== iconNo
    );
    setSelectedPreperationList(newArray);
  };
  return (
    <>
      <Container>
        <FormWrapper>
          {isLoading ? <Loading num={1} /> : null}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              flexDirection='row'
              justifyContents='space-between'
              alignItems='center'
              margin='0 0 23px 0'
              width='100%'
            >
              <Title>세션 수정하기</Title>
              {isEdit ? (
                <SubmitButton type='submit'>저장하기</SubmitButton>
              ) : (
                <SubmitButton onClick={() => setIsEdit(true)}>
                  수정하기
                </SubmitButton>
              )}
            </Flex>
            <InputContainer>
              <InputName>세션명</InputName>
              <Input
                id='title'
                placeholder='세션 제목을 입력하세요. ex) 케틀벨 스윙으로 부하를 주는 운동법'
                aria-invalid={
                  isDirty ? (errors.title ? 'true' : 'false') : undefined
                }
                {...register('title', {
                  required: '세션명을 입력하세요.',
                })}
              />
              {errors.title && (
                <Error role='alert'>{errors.title.message?.toString()}</Error>
              )}
            </InputContainer>
            <InputContainer>
              <InputName>세션 소개</InputName>
              <TextArea
                id='introduceText'
                placeholder='세션 소개를 자유롭게 입력하세요. 최대 1,000자 까지 입력할 수 있습니다.'
                aria-invalid={
                  isDirty
                    ? errors.introduceText
                      ? 'true'
                      : 'false'
                    : undefined
                }
                {...register('introduceText', {
                  required: '세션 소개를 입력하세요.',
                })}
                onChange={() => {}}
              />
              {errors.introduceText && (
                <Error role='alert'>
                  {errors.introduceText.message?.toString()}
                </Error>
              )}
            </InputContainer>
            <InputContainer>
              <InputName>공개 범위</InputName>
              <Flex gap='15px'>
                <CheckBoxContainer
                  onClick={() => isEdit && setAuthority('public')}
                >
                  <CheckBoxInput id='public' type='checkbox' />
                  <label htmlFor='public'>
                    {authority === 'public' ? (
                      <Icon width={22} height={22} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={22} height={22} icon='CheckBox' />
                    )}
                  </label>
                  <Span color='var(--c100)' fontSize='14px'>
                    전체공개
                  </Span>
                </CheckBoxContainer>

                <CheckBoxContainer
                  onClick={() => isEdit && setAuthority('member_only')}
                >
                  <CheckBoxInput id='member_only' type='checkbox' />
                  <label htmlFor='member_only'>
                    {authority === 'member_only' ? (
                      <Icon width={22} height={22} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={22} height={22} icon='CheckBox' />
                    )}
                  </label>
                  <Span color='var(--c100)' fontSize='14px'>
                    회원공개
                  </Span>
                </CheckBoxContainer>
              </Flex>
            </InputContainer>
            <Flex flexDirection='row' gap='30px' width='100%'>
              <InputContainer_noW100>
                <InputName>세션 대표 이미지</InputName>
                <Flex flexDirection='row' gap='10px'>
                  <input
                    id='displayImageFile'
                    type='file'
                    accept={allowedImageFormats}
                    style={{ display: 'none' }}
                    aria-invalid={
                      isDirty
                        ? errors.displayImageFile
                          ? 'true'
                          : 'false'
                        : undefined
                    }
                    {...register('displayImageFile', {
                      validate: {
                        isVerified: () =>
                          displayImage === ''
                            ? '대표이미지를 등록하세요.'
                            : true,
                      },
                    })}
                    onChange={(e) =>
                      e.target.files && addDisplayImageFile(e.target.files[0])
                    }
                  />
                  {displayImage !== '' ? (
                    <UploadImageContainer>
                      <ImageWrapper>
                        <img src={displayImage} />
                      </ImageWrapper>
                      {isEdit && (
                        <DeleteButton onClick={() => deletedisplayImageFile()}>
                          <Icon icon='X' width={9.8} height={9.8} />
                        </DeleteButton>
                      )}
                    </UploadImageContainer>
                  ) : (
                    <label htmlFor='displayImageFile'>
                      <AddImage>
                        <Span>+</Span>
                        <Span>이미지 추가</Span>
                      </AddImage>
                    </label>
                  )}
                </Flex>
                {errors.displayImageFile && (
                  <Error role='alert'>
                    {errors.displayImageFile.message?.toString()}
                  </Error>
                )}
              </InputContainer_noW100>

              <InputContainer_noW100>
                <InputName>준비물</InputName>
                <Flex flexDirection='row' gap='10px' alignItems='flex-start'>
                  <input
                    id='prepare'
                    type='file'
                    accept={allowedImageFormats}
                    style={{ display: 'none' }}
                    aria-invalid={
                      isDirty ? (errors.prepare ? 'true' : 'false') : undefined
                    }
                    {...register('prepare', {
                      validate: {
                        isVerified: () =>
                          selectedPreperationList.length < 1
                            ? '준비물을 추가해주세요'
                            : true,
                      },
                    })}
                  />
                  <PrepareCircle onClick={() => isEdit && handleShow()}>
                    <Span>+ 준비물</Span>
                    <Span>추가</Span>
                  </PrepareCircle>
                  <W300>
                    {selectedPreperationList.length > 0 &&
                      selectedPreperationList.map((item, index) => {
                        return (
                          <PrepareContainer key={index}>
                            <PrepareImageWrapper
                              width='82px'
                              height='82px'
                              backgroundColor='#2c2d34'
                              cursor='default'
                            >
                              <img src={item.iconUrl} />
                              {isEdit && (
                                <OverlayX
                                  onClick={() =>
                                    item.iconNo && deletePrepare(item.iconNo)
                                  }
                                >
                                  <Icon icon='WhiteX' width={15} height={15} />
                                </OverlayX>
                              )}
                              {item.isNecessary && (
                                <PrepareRequiredBox>필수</PrepareRequiredBox>
                              )}
                            </PrepareImageWrapper>
                            <PrepareText>{item.title}</PrepareText>
                          </PrepareContainer>
                        );
                      })}
                  </W300>
                </Flex>
                {errors.prepare && (
                  <Error role='alert'>
                    {errors.prepare.message?.toString()}
                  </Error>
                )}
              </InputContainer_noW100>
            </Flex>
            <InputContainer>
              <InputName>설명 제목</InputName>
              <Input
                id='introducesTitle'
                placeholder='설명 제목을 입력해 주세요 ex) 시작하기 전 알아야 할 것들'
                aria-invalid={
                  isDirty
                    ? errors.introducesTitle
                      ? 'true'
                      : 'false'
                    : undefined
                }
                {...register('introducesTitle', {
                  required: '설명 제목을 입력하세요.',
                })}
              />
              {errors.introducesTitle && (
                <Error role='alert'>
                  {errors.introducesTitle.message?.toString()}
                </Error>
              )}
            </InputContainer>
            <InputContainer>
              <InputName>설명 내용</InputName>
              <TextArea
                id='introducesContext'
                placeholder='설명을 자유롭게 입력하세요. 최대 1,500자 까지 입력할 수 있습니다.'
                aria-invalid={
                  isDirty
                    ? errors.introducesContext
                      ? 'true'
                      : 'false'
                    : undefined
                }
                {...register('introducesContext', {
                  required: '설명 내용를 입력하세요.',
                })}
                onChange={() => {}}
              />
              {errors.introducesContext && (
                <Error role='alert'>
                  {errors.introducesContext.message?.toString()}
                </Error>
              )}
            </InputContainer>
            {/* 동영상 추가 */}
            {mainVideo && (
              <MainVideoInputDetail
                setIsLoading={setIsLoading}
                mainVideo={mainVideo}
                sceneList={sceneList}
                setSceneList={setSceneList}
                isEdit={true}
                errors={errors}
                register={register}
              />
            )}
          </Form>
        </FormWrapper>
        <PreviewScreen
          formData={watchAllFields}
          displayImage={displayImage}
          mainVideoDuration={mainVideo?.duration || 0}
          selectedPreperationList={selectedPreperationList}
          copyrightText={sessionView?.copyrightText}
        />
        <Margin margin='0 -5px 0 0' />
      </Container>
      <PrepareModal
        setSelectedPreperationList={setSelectedPreperationList}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
}
export default EditSessionForm;
