import { logout } from 'utils/logout';
import { Flex, Span } from '../../styles/style';
import { Container, SearchInput, Logo } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from 'components/Icon';
function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const strictList = ['/noCenter', '/applyCenter', '/editCenter', '/pending'];
    const isLogoutShow = strictList.includes(location.pathname);
    return (
        <Container>
            <>
                <Logo onClick={() => navigate('/')}>
                    <Icon icon='LogoText' />
                </Logo>
                {/* <SearchInput placeholder="메뉴를 검색해주세요" /> */}
                {/* <Flex flexDirection="row">홍길동</Flex> */}
                {isLogoutShow && (
                    <Span
                        fontSize='1.3rem'
                        color='var(--c500)'
                        cursor='pointer'
                        onClick={() => logout()}
                    >
                        로그아웃
                    </Span>
                )}
            </>
        </Container>
    );
}
export default Header;
