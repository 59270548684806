import client from './client';
import { withErrorHandling } from './errorHandling';

type postProfileType = {
  centerId: string;
  profileImages: string | null;
  nickname: string;
};
const postProfileRaw = (data: postProfileType) =>
  client.post('/api/center/profile/update', data);
export const postProfile = withErrorHandling(postProfileRaw);
