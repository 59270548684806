import styled from 'styled-components';
type InputType = {
  width?: string;
  height?: string;
  isHidden: boolean;
};
export const InputWrapper = styled.input<InputType>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '49px')};
  padding: 0 16px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--grayscale-c-500, #8a8c99);
  color: white;
  background-color: transparent;
`;
