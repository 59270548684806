import styled from 'styled-components';
type ContainerType = {
  backgroundImage?: string;
};
export const Container = styled.div`
  position: absolute;
  left: 750px;
  top: 0;
  color: white;
  width: 390px;
  height: 844px;
  padding-bottom: 50px;
  max-height: 100vh;
  overflow: auto;
  border: 1px solid var(--background-color);
  z-index: 1;
  cursor: default;
`;
export const BackgroundImage = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 695px;
  background-color: gray;
  background-position: center;
  background-image: ${({ backgroundImage }) =>
    `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0,0,0) 100%), url(${backgroundImage})`};

  background-size: cover;
  box-shadow: 0 50px 50px 0px rgba(0, 0, 0, 0.8) inset;
  z-index: 0;
`;
export const TopBar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  padding: 15px 25px 25px 30px;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  z-index: 2;
`;
export const PhoneStatusImageWrapper = styled.div`
  position: relative;
  width: 66px;
  z-index: 0;
`;
export const MainInfoContainer = styled.div`
  position: relative;
  height: 555px;
  margin-top: 140px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 700;
  z-index: 2;
`;
export const SubInfoContainer = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 21px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  letter-spacing: -0.5px;
`;
export const DisplayName = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CompletionCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 9px;
  margin-top: -18px;
  align-items: center;
`;
export const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CircleImage = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  :not(:first-child) {
    margin-left: -11px;
  }
`;
export const CompletionCountText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.5px;
`;
export const SubInfoTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
`;
export const IntroduceText = styled.textarea`
  background-color: transparent;
  width: 100%;
  height: 70px;
  border: none;
  color: white;
  resize: none;
  font-size: 1.4rem;
  overflow-y: scroll;
  :focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 설정 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey; /* 스크롤바 색상 설정 */
    border-radius: 4px; /* 스크롤바 모서리 둥글게 */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* 스크롤바 트랙(경로) 색상 설정 */
  }

  /* Firefox용 스크롤바 스타일 */
  & {
    scrollbar-width: thin;
    scrollbar-color: grey transparent;
  }
`;
export const SubItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const IntroducesTitle = styled.div`
  color: white;
  font-size: 1.6rem;
  font-weight: 700;
`;
export const IntroducesContextWrapper = styled.div`
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 17px;
  height: 70px;
`;
export const IntroducesContext = styled.textarea`
  background-color: transparent;
  width: 100%;
  height: 70px;
  border: none;
  color: white;
  resize: none;
  overflow-y: scroll;
  :focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 설정 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey; /* 스크롤바 색상 설정 */
    border-radius: 4px; /* 스크롤바 모서리 둥글게 */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent; /* 스크롤바 트랙(경로) 색상 설정 */
  }

  /* Firefox용 스크롤바 스타일 */
  & {
    scrollbar-width: thin;
    scrollbar-color: grey transparent;
  }
`;

export const StartButton = styled.div`
  width: 350px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.5px;
  padding: 0px 20px;
  margin: 35px auto;
  color: black;
  border-radius: 10px;
`;
export const HomeIndicatorWrapper = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 8px;
`;
export const HomeIndicator = styled.div`
  width: 134px;
  height: 5px;
  border-radius: 10px;
  background-color: white;
`;
export const CenterInfoCircle = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 100%;
  overflow: hidden;
`;
