export const getRatio = (
  width: number,
  height: number
): 'landscape' | 'portrait' => {
  const standardRatio = 16 / 9; // 16:9 비율
  const reversedStandardRatio = 9 / 16; // 9:16 비율
  let ratio;
  if (width > height) {
    // L 비디오
    ratio = width / height;
    if (
      Math.round(ratio * 100) / 100 ===
      Math.round(standardRatio * 100) / 100
    ) {
      return 'landscape';
    } else {
      return 'landscape';
    }
  } else {
    // P 비디오
    ratio = height / width;
    if (
      Math.round(ratio * 100) / 100 ===
      Math.round(reversedStandardRatio * 100) / 100
    ) {
      return 'portrait';
    } else {
      return 'portrait';
    }
  }
};

export const getQuality = (width: number, height: number) => {
  const maxDimension = Math.max(width, height);
  if (maxDimension < 720) {
    return 'LD (640x480)';
  } else if (maxDimension >= 720 && maxDimension < 1080) {
    return 'HD (1280x720)';
  } else if (maxDimension >= 1080) {
    return 'FHD (1920x1080)';
  }
  return '';
};

export const getMMSSFormat = (second: number) => {
  const time = second / 1000;
  return (
    String(Math.floor(time / 60)).padStart(2, '0') +
    '분 ' +
    String(Math.floor(time % 60)).padStart(2, '0') +
    '초'
  );
};

export const getMMSSFormat_colon = (second: number) => {
  const time = second / 1000;
  return (
    String(Math.floor(time / 60)).padStart(2, '0') +
    ' : ' +
    String(Math.floor(time % 60)).padStart(2, '0')
  );
};

export const getMS = (second: number) => {
  return second * 1000;
};

export const moveCursorToEnd = (e: React.MouseEvent<HTMLInputElement>) => {
  const el = e.currentTarget;
  el.selectionStart = el.selectionEnd = el.value.length;
};
