import { useEffect, useState, useCallback } from 'react';
import Icon from 'components/Icon';
import { Flex, Span } from 'styles/style';
import { useNavigate } from 'react-router-dom';
import {
  AddSession,
  Container,
  FilteringContainer,
  FilteringItem,
  PrivateLabel,
  PublicLabel,
  SessionContainer,
  SessionTime,
  SessionTitle,
} from './style';
import { useCenterStore } from 'store/auth';
import { getMMSSFormat } from 'constants/video';
import { SessionType } from 'constants/type';
import { postSessionList } from 'apis/session';

const SessionList = () => {
  const navigate = useNavigate();
  const { center } = useCenterStore();
  const [authority, setAuthority] = useState<'public' | 'member_only' | '-'>(
    '-'
  );
  const [sessionList, setSessionList] = useState<SessionType[]>([]);
  const [offset, setOffset] = useState<number>(0);
  useEffect(() => {
    setOffset(0);
  }, [authority]);
  useEffect(() => {
    getList();
  }, [offset, authority]);
  const handleScroll = useCallback(() => {
    //반정도 내려왔을 떄 호출하려고
    const scrolled =
      Math.floor(window.innerHeight + document.documentElement.scrollTop) / 2;
    const totalScroll = Math.floor(document.documentElement.offsetHeight / 2);
    const offset = 5; // 오차 범위 설정

    if (Math.abs(scrolled - totalScroll) <= offset) {
      // 두 값의 차이가 offset 이하일 경우
      setOffset((prevOffset) => prevOffset + 10);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  const getList = async () => {
    try {
      const response = await postSessionList({
        centerId: center.centerId,
        failedToComplete: false,
        authority,
        limit: 10,
        offset,
        orderBy: 'desc',
      });
      const result = response;
      const { sessionList } = result;
      if (sessionList?.length > 0 && offset === 0) {
        setSessionList(sessionList);
      } else if (sessionList) {
        setSessionList((prevList) => [...prevList, ...sessionList]);
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div>
      <Flex flexDirection='row' justifyContents='space-between' gap='35px'>
        <Span fontSize='18px' fontWeight='700' whiteSpace='nowrap'>
          전체 세션
        </Span>
        <FilteringContainer>
          <FilteringItem
            onClick={() => setAuthority('-')}
            active={authority === '-'}
          >
            모두
          </FilteringItem>
          |
          <FilteringItem
            onClick={() => setAuthority('member_only')}
            active={authority === 'member_only'}
          >
            회원공개
          </FilteringItem>
          |
          <FilteringItem
            onClick={() => setAuthority('public')}
            active={authority === 'public'}
          >
            전체공개
          </FilteringItem>
        </FilteringContainer>
      </Flex>
      <Container>
        <AddSession onClick={() => navigate('/center/session/add')}>
          <Icon icon='Plus' width={30} height={30} />
          <Span fontSize='18px' lineHeight='22px'>
            세션
            <br />
            추가하기
          </Span>
        </AddSession>
        {sessionList.length > 0 &&
          sessionList.map((session, index) => {
            return (
              <SessionContainer
                key={index}
                image={session.displayImage?.originalImage}
                onClick={() =>
                  navigate(
                    `/center/session/detail?sessionId=${session.sessionId}`
                  )
                }
              >
                {session.authority === 'public' ? (
                  <PublicLabel>전체공개</PublicLabel>
                ) : (
                  <PrivateLabel>회원공개</PrivateLabel>
                )}
                <SessionTitle>{session.title}</SessionTitle>
                <SessionTime>
                  {getMMSSFormat(session.video.duration)}
                </SessionTime>
              </SessionContainer>
            );
          })}
      </Container>
    </div>
  );
};
export default SessionList;
