import React, { useEffect, useRef, useState } from 'react';
import Hls, { ErrorData } from 'hls.js';
import { VIDEOLOAD_ALERT_TEXT } from 'constants/alertText';

type VideoPlayerType = {
  src: string;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
  controls?: boolean;
  flipped?: boolean;
  muted?: boolean;
  endTime?: number;
  setIsPaused?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConvertEnd?: React.Dispatch<React.SetStateAction<boolean>>;
};

const VideoPlayer = ({
  src,
  videoRef: externalVideoRef,
  controls,
  flipped,
  muted,
  endTime,
  setIsPaused,
  setIsConvertEnd,
}: VideoPlayerType) => {
  const internalVideoRef = useRef<HTMLVideoElement | null>(null);
  const retryInterval = 5000; // 초 (5000ms)
  const retryCountRef = useRef(0);
  const maxRetries = 5; // 최대 재시도 횟수 설정 (옵션)
  const timeStamp = new Date().getTime();

  const finalVideoRef = externalVideoRef || internalVideoRef;
  useEffect(() => {
    if (Hls.isSupported() && finalVideoRef.current) {
      const hls = new Hls();
      const onError = (event: any, data: ErrorData) => {
        // 재시도
        if (retryCountRef.current >= maxRetries) {
          // 최대 재시도 횟수에 도달하면 더 이상 시도하지 않음.
          return;
        }
        setTimeout(() => {
          hls.loadSource(`${src}?${timeStamp}`);
          hls.attachMedia(finalVideoRef.current as HTMLMediaElement);
          retryCountRef.current += 1; // 재시도 횟수 증가
        }, retryInterval); // 2초 후 재시도
      };
      hls.on(Hls.Events.ERROR, onError);

      hls.loadSource(`${src}?${timeStamp}`);
      hls.attachMedia(finalVideoRef.current);

      return () => {
        hls.destroy();
      };
    }
  }, [src, finalVideoRef]);

  useEffect(() => {
    try {
      if (Hls.isSupported() && finalVideoRef.current) {
        const hls = new Hls();
        hls.loadSource(`${src}?${timeStamp}`);
        hls.attachMedia(finalVideoRef.current);
      }
    } catch (e) {
      alert(VIDEOLOAD_ALERT_TEXT);
    }
  }, [src, finalVideoRef]);

  //endTime 설정했을 때
  useEffect(() => {
    const handleTimeUpdate = () => {
      if (
        endTime &&
        finalVideoRef.current &&
        finalVideoRef.current.currentTime >= endTime
      ) {
        finalVideoRef.current.pause();
        finalVideoRef.current.currentTime = 0;
        setIsPaused && setIsPaused(true);
      }
    };

    if (finalVideoRef.current) {
      finalVideoRef.current.addEventListener('timeupdate', handleTimeUpdate);
      finalVideoRef.current.addEventListener(
        'canplay',
        () => setIsConvertEnd && setIsConvertEnd(true)
      );
    }

    return () => {
      if (finalVideoRef.current) {
        finalVideoRef.current.removeEventListener(
          'timeupdate',
          handleTimeUpdate
        );
      }
    };
  }, [endTime, finalVideoRef]);
  return (
    <video
      controls={controls}
      ref={finalVideoRef}
      muted={muted}
      style={{
        transform: flipped ? 'scaleX(-1)' : 'scaleX(1)',
      }}
    ></video>
  );
};

export default VideoPlayer;
