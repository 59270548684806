import Icon from 'components/Icon';
import {
  ControlsContainer,
  PlayButtonWrapper,
  ProgressTime,
} from 'components/MainVideoInput/style';
import VideoPlayer from 'components/VideoPlayer';
import { VideoType } from 'constants/type';
import { getMMSSFormat_colon } from 'constants/video';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import React, { useEffect, useRef, useState } from 'react';
import { Flex, Span } from 'styles/style';
import {
  Container,
  ConvertButtonDiv,
  ConvertButtonWrapper,
  CurrentBar,
  ProgressBar,
  VideoWrapper,
} from './style';
type FeedbackVideoContainerType = {
  // 필요한거 다 적음
  feedbackVideo: VideoType;
  userVideo: VideoType;
};
const FeedbackVideoContainer = ({
  feedbackVideo,
  userVideo,
}: FeedbackVideoContainerType) => {
  const {
    videoRef: feedbackVideoRef,
    currentBarHandler: currentBarHandler_f,
    play: playFeedbackVideo,
    pause: pauseFeedbackVideo,
  } = useVideoControl();
  const {
    videoRef: userVideoRef,
    currentBarHandler: currentBarHandler_u,
    play: playUserVideo,
    pause: pauseUserVideo,
  } = useVideoControl();
  const { currentTime } = useVideoMetadata({
    videoRef: feedbackVideoRef,
    video: feedbackVideo,
  });
  const [duration, setDuration] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  useEffect(() => {
    setDuration(Math.min(feedbackVideo.duration, userVideo.duration));
  }, [feedbackVideo, userVideo]);

  const playAllVideos = () => {
    playFeedbackVideo();
    playUserVideo();
    setIsPaused(false);
  };
  const pauseAllVideos = () => {
    pauseFeedbackVideo();
    pauseUserVideo();
    setIsPaused(true);
  };
  return (
    <>
      <Container ratio={feedbackVideo.ratio}>
        <VideoWrapperComponent
          videoRef={feedbackVideoRef}
          video={feedbackVideo}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          duration={duration}
        />
        <VideoWrapperComponent
          videoRef={userVideoRef}
          video={userVideo}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          muted
          duration={duration}
        />
      </Container>
      <ControlsContainer>
        {isPaused ? (
          <PlayButton icon='PlayButton' action={playAllVideos} />
        ) : (
          <PlayButton icon='PauseButton' action={pauseAllVideos} />
        )}
        <Flex flexDirection='column' gap='7px'>
          <ProgressTime>
            {getMMSSFormat_colon(currentTime)} / {getMMSSFormat_colon(duration)}
          </ProgressTime>
          <ProgressBar
            onClick={(e) => {
              currentBarHandler_f(e);
              currentBarHandler_u(e);
            }}
          >
            <CurrentBar width={(currentTime / duration) * 100} />
          </ProgressBar>
        </Flex>
      </ControlsContainer>
    </>
  );
};
type ConvertButtonType = {
  flipped: boolean;
  setFlipped: React.Dispatch<React.SetStateAction<boolean>>;
};
const ConvertButton = ({ flipped, setFlipped }: ConvertButtonType) => {
  return (
    <ConvertButtonWrapper onClick={() => setFlipped(!flipped)}>
      <ConvertButtonDiv>
        <Icon icon='FilterVertical' width={12} height={12} />
        <Span>좌우반전</Span>
      </ConvertButtonDiv>
    </ConvertButtonWrapper>
  );
};

type PlayButtonProps = {
  icon: 'PlayButton' | 'PauseButton';
  action: () => void;
};

const PlayButton = ({ icon, action }: PlayButtonProps) => (
  <PlayButtonWrapper onClick={action}>
    <Icon icon={icon} width={20} height={20} />
  </PlayButtonWrapper>
);

type VideoWrapperProps = {
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  video: VideoType;
  isPaused: boolean;
  setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
  muted?: boolean;
  duration?: number;
};
const VideoWrapperComponent = ({
  videoRef,
  video,
  isPaused,
  setIsPaused,
  muted,
  duration,
}: VideoWrapperProps) => {
  const [flipped, setFlipped] = useState(false);

  return (
    <VideoWrapper ratio={video.ratio}>
      <VideoPlayer
        videoRef={videoRef}
        src={video.streamingUrl}
        flipped={flipped}
        muted={muted}
        endTime={duration && duration / 1000} //VideoPlayer는 초단위를 쓰고 있음
        setIsPaused={setIsPaused}
      />
      {isPaused && <ConvertButton flipped={flipped} setFlipped={setFlipped} />}
    </VideoWrapper>
  );
};

export default FeedbackVideoContainer;
