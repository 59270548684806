import styled from 'styled-components';

type BackgroundType = {
  margin?: string;
  padding?: string;
};
export const Background = styled.div<BackgroundType>`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  padding-top: 100px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  overflow: auto;
`;
type ContainerType = {
  width?: string;
};
export const Container = styled.div<ContainerType>`
  width: ${({ width }) => (width ? width : '512px')};
  background-color: #2c2d34;
  padding: 46px 36px;
  color: white;
  justify-content: center;
`;
export const Header = styled.div`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
`;
export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.5px;
`;
type RequiredButtonType = {
  active: boolean;
};
export const RequiredButton = styled.div<RequiredButtonType>`
  width: 99px;
  height: 48px;
  background-color: transparent;
  border: ${({ active }) =>
    active ? '1px solid var(--primary)' : '1px solid white'};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const AddButton = styled.div`
  display: flex;
  width: 350px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primary);
  color: black;
  cursor: pointer;
`;
