import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { Container, Item } from './style';
import { useRef } from 'react';
import { postDeleteSession } from 'apis/session';

type Type = {
  sessionId: string;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const SessionToggleMenu = ({
  sessionId,
  isMenuOpen,
  setIsMenuOpen,
}: Type) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null); // reference to the Container

  useEffect(() => {
    // function to handle outside click
    function handleOutsideClick(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false); // close the menu if clicked outside
      }
    }

    // add the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // cleanup the event listener
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [setIsMenuOpen]);

  const deleteSession = async () => {
    const response = await postDeleteSession({ params: { sessionId } });

    const result = response;
    if (!result) return;
    else {
      window.history.back();
    }
  };
  return (
    <>
      {isMenuOpen && (
        <Container ref={containerRef}>
          <Item
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/center/session/edit?sessionId=${sessionId}`);
            }}
          >
            수정하기
          </Item>
          <Item
            color='var(--semantic-red)'
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm('정말 삭제하시겠습니까?')) {
                deleteSession();
              }
            }}
          >
            삭제하기
          </Item>
        </Container>
      )}
    </>
  );
};
