import Icon from 'components/Icon';
import { Span } from 'styles/style';
import { Container } from './style';

type NotificationLine = {
  type: string;
  text: string;
  backgroundColor?: string;
  margin?: string;
};
export const NotificationLine = ({
  type,
  text,
  backgroundColor,
  margin,
}: NotificationLine) => {
  return (
    <Container backgroundColor={backgroundColor} margin={margin}>
      {type === 'alert' && <Icon icon='Alert' width={17} height={17} />}
      <Span fontSize='14px'>{text}</Span>
    </Container>
  );
};
