import ApplyCenterForm from 'components/ApplyCenterForm';
import styled from 'styled-components';

const ApplyCenterPage = () => {
  return (
    <ApplyCenterFormWrapper>
      <ApplyCenterForm />
    </ApplyCenterFormWrapper>
  );
};

export default ApplyCenterPage;

const ApplyCenterFormWrapper = styled.div`
  margin: 100px auto;
`;
