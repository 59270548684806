import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 258px;
  height: 150px;
  overflow-y: scroll;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #201f1f;
  border-radius: 4px;
  padding: 15px 10px;
  gap: 9px;
  z-index: 999;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
`;
