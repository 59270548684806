import { useEffect, useState, useRef } from 'react';
import { Flex, Span } from 'styles/style';
import useDebounce from 'hooks/useDebounce';
import { InputContainer, AddImage } from 'styles/FormStyle';
import {
  MainVideoContainer,
  MainVideoWrapper,
  MetaDataContainer,
  MetaDataColumn,
  MetaDataTitle,
  MetaDataValue,
  VideoChangeButton,
  ControlsContainer,
  ProgressTime,
  ProgressBar,
  CurrentBar,
  PlayButtonWrapper,
  HorizontalBox,
  VerticalBox,
  SortButton,
} from './style';
import { Title2 } from '../AddSessionForm/style';
import Icon from 'components/Icon';
import {
  getQuality,
  getRatio,
  getMMSSFormat,
  getMMSSFormat_colon,
} from 'constants/video';
import Scene from 'components/Scene';
import Loading from 'components/Loading';
import FeedbackVideoInput from 'components/FeedbackVideoInput';
import { SceneType, SessionFormType } from 'constants/type';
import { useForm } from 'react-hook-form';
import { Error } from 'styles/FormStyle';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { AddSceneText } from 'components/Scene/style';
import { AddScene } from 'components/AddScene';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import { VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';

export type MainVideoInputType = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mainVideo: File | null;
  setMainVideo: React.Dispatch<React.SetStateAction<File | null>>;
  mainVideoDuration: number;
  setMainVideoDuration: React.Dispatch<React.SetStateAction<number>>;
  mainVideoRatio: 'landscape' | 'portrait';
  setMainVideoRatio: React.Dispatch<
    React.SetStateAction<'landscape' | 'portrait'>
  >;
  feedbackVideo: File | null;
  setFeedbackVideo: React.Dispatch<React.SetStateAction<File | null>>;
  sceneList: SceneType[];
  setSceneList: React.Dispatch<React.SetStateAction<SceneType[]>>;
  setMainVideoSize: React.Dispatch<React.SetStateAction<any>>;
  errors: FieldErrors<any>;
  register: UseFormRegister<SessionFormType>;
};
const MainVideoInput = ({
  setIsLoading,
  mainVideo,
  setMainVideo,
  feedbackVideo,
  setFeedbackVideo,
  sceneList,
  setSceneList,
  mainVideoDuration,
  setMainVideoDuration,
  mainVideoRatio,
  setMainVideoRatio,
  setMainVideoSize,
  errors,
  register,
}: MainVideoInputType) => {
  const [mainVideoSrc, setMainVideoSrc] = useState('');
  const [lastClick, setLastClick] = useState(0);
  const [isVideoCanPlay, setIsVideoCanPlay] = useState(false);

  //video hook
  const { videoRef, isVideoPlaying, play, pause, currentBarHandler } =
    useVideoControl();
  const { duration, ratio, quality, currentTime, setCurrentTime } =
    useVideoMetadata({
      videoRef,
      video: mainVideo,
      setIsVideoCanPlay,
      setIsLoading,
      setMainVideoSize,
    });

  const {
    trigger,
    formState: { isDirty },
  } = useForm<SessionFormType>();

  useEffect(() => {
    trigger('mainVideo');
  }, [mainVideo]);
  useEffect(() => {
    setMainVideoDuration(duration * 1000);
  }, [duration]);
  useEffect(() => {
    setMainVideoRatio(ratio);
  }, [ratio]);

  const addMainVideo = (file: File) => {
    if (!file) {
      return;
    }
    if (file.size > 1 * 1024 * 1024 * 1024) {
      alert(VIDEOUPLOAD_ALERT_TEXT);
      return;
    }
    const videoElement = document.createElement('video');
    const objectUrl = URL.createObjectURL(file);

    videoElement.src = objectUrl;
    videoElement.addEventListener('loadedmetadata', () => {
      const videoDuration = videoElement.duration;
      if (videoDuration > 30 * 60) {
        alert(VIDEOUPLOAD_ALERT_TEXT);
        URL.revokeObjectURL(objectUrl);
        return;
      } else {
        setIsLoading(true);
        setIsVideoCanPlay(false);
        setCurrentTime(0);

        setMainVideo(file);
        setMainVideoSrc(objectUrl);
        setSceneList([]);
        setFeedbackVideo(null);
      }
    });
  };

  const sortScene = () => {
    const newSceneList = sceneList.sort(
      (a, b) => a.sceneStartAt - b.sceneStartAt
    );
    setSceneList([...newSceneList]);
  };

  return (
    <>
      <InputContainer>
        <input
          id='mainVideo'
          type='file'
          accept='video/*'
          style={{ display: 'none' }}
          aria-invalid={
            isDirty ? (errors.mainVideo ? 'true' : 'false') : undefined
          }
          {...register('mainVideo', {
            validate: {
              isVerified: () =>
                mainVideoSrc === '' ? '세션 영상을 추가하세요.' : true,
            },
          })}
          onChange={(e) => e.target.files && addMainVideo(e.target.files[0])}
        />
        {mainVideoSrc !== '' ? (
          <>
            <MainVideoContainer>
              {mainVideoRatio === 'landscape' ? (
                <HorizontalBox>
                  <video src={mainVideoSrc} ref={videoRef} />
                </HorizontalBox>
              ) : (
                <VerticalBox>
                  <video src={mainVideoSrc} ref={videoRef} />
                </VerticalBox>
              )}
              <Flex
                flex={1}
                flexDirection='column'
                gap='41px'
                justifyContents='center'
              >
                <Flex flexDirection='row' justifyContents='space-between'>
                  <MetaDataContainer>
                    <MetaDataColumn>
                      <MetaDataTitle>영상길이</MetaDataTitle>
                      <MetaDataValue>
                        {isVideoCanPlay
                          ? getMMSSFormat(mainVideoDuration)
                          : '-'}
                      </MetaDataValue>
                    </MetaDataColumn>
                    <MetaDataColumn>
                      <MetaDataTitle>비율</MetaDataTitle>
                      <MetaDataValue>
                        {isVideoCanPlay
                          ? `${
                              ratio === 'landscape'
                                ? '가로형 (16:9)'
                                : '세로형 (9:16)'
                            }`
                          : '-'}
                      </MetaDataValue>
                    </MetaDataColumn>
                    <MetaDataColumn>
                      <MetaDataTitle>화질</MetaDataTitle>
                      <MetaDataValue>
                        {isVideoCanPlay ? quality : '-'}
                      </MetaDataValue>
                    </MetaDataColumn>
                  </MetaDataContainer>
                  <label htmlFor='mainVideo'>
                    <VideoChangeButton>영상 변경하기</VideoChangeButton>
                  </label>
                </Flex>
                <ControlsContainer>
                  {isVideoPlaying ? (
                    <PlayButtonWrapper onClick={pause}>
                      <Icon icon='PauseButton' width={20} height={20} />
                    </PlayButtonWrapper>
                  ) : (
                    <PlayButtonWrapper onClick={play}>
                      <Icon icon='PlayButton' width={20} height={20} />
                    </PlayButtonWrapper>
                  )}
                  <Flex flexDirection='column' gap='7px'>
                    <ProgressTime>
                      {getMMSSFormat_colon(currentTime)} /{' '}
                      {isVideoCanPlay
                        ? getMMSSFormat_colon(mainVideoDuration)
                        : '00:00'}
                    </ProgressTime>
                    <ProgressBar onClick={(e) => currentBarHandler(e)}>
                      <CurrentBar
                        width={(currentTime / mainVideoDuration) * 100}
                      />
                    </ProgressBar>
                  </Flex>
                </ControlsContainer>
              </Flex>
            </MainVideoContainer>
            <Flex margin='30px 0 10px 0'>
              <AddSceneText>씬 추가하기</AddSceneText>
            </Flex>
            <AddScene
              ratio={mainVideoRatio}
              duration={mainVideoDuration}
              sceneList={sceneList}
              setSceneList={setSceneList}
              video={mainVideoSrc}
            />
            {sceneList.filter((scene) => scene.sceneType === 'custom_scene')
              .length > 0 && (
              <Flex margin='0px 0 10px 0' justifyContents='space-between'>
                <AddSceneText>추가한 씬</AddSceneText>
                <SortButton onClick={() => sortScene()}>정렬하기</SortButton>
              </Flex>
            )}
            {sceneList
              .filter((scene) => scene.sceneType === 'custom_scene')
              .map((scene, i) => {
                return (
                  <Scene
                    key={scene.sceneStartAt + '_' + i}
                    index={i}
                    scene={scene}
                    video={mainVideoSrc}
                    setIsLoading={setIsLoading}
                    ratio={mainVideoRatio}
                    duration={mainVideoDuration}
                    sceneList={sceneList}
                    setSceneList={setSceneList}
                    errors={errors}
                    register={register}
                    isEdit={true}
                  />
                );
              })}
            <FeedbackVideoInput
              setIsLoading={setIsLoading}
              ratio={mainVideoRatio}
              sceneList={sceneList}
              setSceneList={setSceneList}
              feedbackVideo={feedbackVideo}
              setFeedbackVideo={setFeedbackVideo}
              duration={mainVideoDuration}
              // errors={errors}
              // register={register}
            />
          </>
        ) : (
          <>
            <Title2>세션 영상 추가</Title2>
            <label htmlFor='mainVideo'>
              <AddImage>
                <Span>+</Span>
                <Span>영상 추가</Span>
              </AddImage>
            </label>
          </>
        )}
        {errors.mainVideo && mainVideoSrc === '' && (
          <Error role='alert'>{errors.mainVideo.message?.toString()}</Error>
        )}
      </InputContainer>
    </>
  );
};
export default MainVideoInput;
