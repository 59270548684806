import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
export const ImageWrapper = styled.div`
  width: 260px;
  height: 260px;
`;

export const ApplyButton = styled.button`
  width: 115px;
  height: 48px;
  display: inline-flex;
  padding: 15px 26px 16px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--grayscale-c-300, #d3d4dc);
  color: var(--c300);
  margin: 0 auto;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  white-space: nowrap;
`;
export const GrayText = styled.div`
  margin-top: 26px;
  color: var(--c500);
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  white-space: nowrap;
`;
export const ApplyStaffText = styled.div`
  color: var(--c300);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-decoration-line: underline;
  white-space: nowrap;
  cursor: pointer;
`;
