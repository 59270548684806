import * as Icons from '../components/Icon/Icons';
export type IconOption = keyof typeof Icons;
export type navProps = {
  url: string;
  name: string;
};
export type subListProps = {
  url: string;
  name: string;
  icon: IconOption;
  subUrl2?: navProps[];
};
export type listProps = {
  url: string;
  name: string;
  subUrl: subListProps[];
};
export const list: listProps[] = [
  {
    url: 'center',
    name: '센터',
    subUrl: [
      {
        url: '/dashboard',
        name: '대시보드',
        icon: 'DashBoard',
      },
      {
        url: '/user',
        name: '회원관리',
        icon: 'ManageUser',
        subUrl2: [
          {
            url: '/list',
            name: '회원 목록',
          },
          {
            url: '/manage',
            name: '회원 승인요청',
          },
        ],
      },
      {
        url: '/staff',
        name: '센터관리',
        icon: 'ManageUser',
        subUrl2: [
          {
            url: '/profile',
            name: '센터 정보',
          },
          {
            url: '/list',
            name: '스태프 목록',
          },
          {
            url: '/manage',
            name: '스태프 승인요청',
          },
        ],
      },
      {
        url: '/session',
        name: '세션',
        icon: 'Session',
      },
      {
        url: '/feedback',
        name: '피드백',
        icon: 'Session',
      },
      {
        url: '/community',
        name: '커뮤니티',
        icon: 'Community',
      },
      // {
      //   url: '/alarm',
      //   name: '알림',
      //   icon: 'Alarm',
      // },
    ],
  },
  {
    url: 'setting',
    name: '설정',
    subUrl: [
      {
        url: '/profile',
        name: '기본정보',
        icon: 'Profile',
      },
      // {
      //   url: '/pay',
      //   name: '결제내역',
      //   icon: 'Pay',
      // },
      {
        url: '/terms',
        name: '이용약관',
        icon: 'Terms',
        subUrl2: [
          {
            url: '/provision',
            name: '이용약관',
          },
          {
            url: '/privacy',
            name: '개인정보 처리방침',
          },
          {
            url: '/marketing',
            name: 'E-mail 및 SMS 광고성 정보 수신 동의',
          },
        ],
      },
    ],
  },
];
