import { useEffect, useState } from 'react';
import { Flex, Margin, Span, Container, Head } from 'styles/style';
import { useCenterStore } from 'store/auth';
import { ManageTable } from 'components/ManageTable';
import { ListInfoType } from 'constants/type';
import { postProposalStaffList } from 'apis/user';
const ManageStaffPage = () => {
  const [staffList, setStaffList] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const { center } = useCenterStore();
  const [staffListInfo, setStaffListInfo] = useState<ListInfoType>({
    count: 0,
    isLastOffset: false,
    limit: 0,
    offset: 0,
    totalCount: 0,
  });
  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    getList();
  }, [offset]);
  const getList = async () => {
    const response = await postProposalStaffList({
      centerId: center.centerId,
    });
    const result = response;
    if (!result) return;

    const { stafflist, listInfo } = result;
    if (stafflist) {
      setStaffList(stafflist);
    }
    if (listInfo) {
      setStaffListInfo(listInfo);
    }
  };
  return (
    <>
      <Container>
        <Head>스태프 요청 리스트</Head>
        <Margin margin='30px 0 0' />
        <Flex justifyContents='space-between'>
          <Span color='var(--c100)'>총 {staffListInfo.totalCount}명</Span>
        </Flex>
        <ManageTable
          type='staff'
          dataList={staffList}
          dataInfo={staffListInfo}
          setOffset={setOffset}
        />
      </Container>
    </>
  );
};
export default ManageStaffPage;
