import { useState, useEffect } from 'react';
import { Flex } from 'styles/style';
import { Input, VerticalBox, HorizontalBox, TimeInput } from './style';
import {
  MetaDataContainer,
  MetaDataColumn,
  MetaDataTitle,
  MetaDataValue,
  VideoChangeButton,
} from '../MainVideoInput/style';
import Icon from 'components/Icon';
import { IconWrapper } from 'styles/FormStyle';
import SkipToggleButton from 'components/SkipToggleButton';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { SceneType, SessionFormType } from 'constants/type';

// 컴포넌트 type
type SceneComponentType = {
  scene: SceneType;
  index: number;
  video: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  ratio: string;
  duration: number;
  sceneList: SceneType[];
  setSceneList: React.Dispatch<React.SetStateAction<SceneType[]>>;
  errors: FieldErrors<any>;
  register: UseFormRegister<SessionFormType>;
  isEdit: boolean;
};
const Scene = ({
  scene: scene_prop,
  index,
  ratio,
  sceneList,
  setSceneList,
  isEdit,
}: SceneComponentType) => {
  const [scene, setScene] = useState<SceneType>(scene_prop);

  const removeScene = (index: number) => {
    setSceneList((prev) => prev.filter((v, i) => i !== index));
  };

  const setCanSkipStep = (index: number, value: boolean) => {};
  return (
    <>
      <Flex flexDirection='column' gap='10px' flex={1}>
        <Flex flexDirection='row' gap='15px'>
          {ratio === 'landscape' ? (
            <HorizontalBox>
              <img
                src={scene.thumbnailUrl}
                alt='씬 썸네일 이미지'
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null; // 재귀적인 호출을 방지
                  target.style.display = 'none';
                }}
              />
            </HorizontalBox>
          ) : (
            <VerticalBox>
              <img
                src={scene.thumbnailUrl}
                alt='씬 썸네일 이미지'
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.onerror = null; // 재귀적인 호출을 방지
                  target.style.display = 'none';
                }}
              />
            </VerticalBox>
          )}
          <Flex
            flexDirection='column'
            gap='41px'
            justifyContents='center'
            flex={1}
          >
            <Flex
              flexDirection='row'
              justifyContents='space-between'
              alignItems='center'
              gap='15px'
            >
              <MetaDataContainer>
                <MetaDataColumn>
                  <MetaDataTitle>시작 시간</MetaDataTitle>
                  <MetaDataValue>
                    <TimeInput
                      readOnly={true}
                      value={Math.floor(scene.sceneStartAt / 1000 / 60)
                        .toString()
                        .padStart(2, '0')}
                    />
                    분{' '}
                    <TimeInput
                      readOnly={true}
                      value={Math.floor((scene.sceneStartAt / 1000) % 60)
                        .toString()
                        .padStart(2, '0')}
                    />
                    초
                  </MetaDataValue>
                </MetaDataColumn>
                <MetaDataColumn>
                  <MetaDataTitle>종료 시간</MetaDataTitle>
                  <MetaDataValue>
                    <TimeInput
                      readOnly={true}
                      value={Math.floor(scene.sceneEndAt / 1000 / 60)
                        .toString()
                        .padStart(2, '0')}
                    />
                    분{' '}
                    <TimeInput
                      readOnly={true}
                      value={Math.floor((scene.sceneEndAt / 1000) % 60)
                        .toString()
                        .padStart(2, '0')}
                    />
                    초
                  </MetaDataValue>
                </MetaDataColumn>
                <MetaDataColumn>
                  <SkipToggleButton
                    id={`scene_${index}`}
                    index={index}
                    canSkipStep={scene.canSkipStep}
                    setCanSkipStep={setCanSkipStep}
                  />
                </MetaDataColumn>
              </MetaDataContainer>
              <Flex flexDirection='column' gap='5px' width='100%' height='100%'>
                <Flex flexDirection='row' gap='5px'>
                  <Input readOnly={true} value={scene.title || ''} />
                  {isEdit && (
                    <IconWrapper>
                      <Icon
                        icon='RedX'
                        color='var(--semantic-red)'
                        width={23}
                        height={23}
                        onClick={() => removeScene(index)}
                      />
                    </IconWrapper>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default Scene;
