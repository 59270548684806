import { Flex, Span } from 'styles/style';
import Icon from '../Icon';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
type SkipToggleButtonType = {
  id: string;
  index: number;
  canSkipStep: boolean;
  setCanSkipStep: (index: number, value: boolean) => void;
};
const SkipToggleButton = ({
  id,
  index,
  canSkipStep,
  setCanSkipStep,
}: SkipToggleButtonType) => {
  const handleToggle = () => {
    setCanSkipStep(index, !canSkipStep);
  };
  return (
    <>
      <Input id={`skip_${id}`} type='checkbox' onChange={handleToggle} />
      <label htmlFor={`skip_${id}`}>
        <Container>
          {canSkipStep ? (
            <Icon icon='ToggleCheckButton_Active' width={16} height={16} />
          ) : (
            <Icon icon='ToggleCheckButton' width={16} height={16} />
          )}
          <Span
            fontSize='14px'
            fontWeight='400'
            color={canSkipStep ? 'var(--primary)' : 'white'}
          >
            건너뛰기 가능
          </Span>
        </Container>
      </label>
    </>
  );
};
export default SkipToggleButton;

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;
const Input = styled.input`
  display: none;
`;
