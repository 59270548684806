import { postFeedbackList } from 'apis/feedback';
import FeedbackItem from 'components/FeedbackItem';
import { Line } from 'components/FeedbackItem/style';
import Icon from 'components/Icon';
import { FeedbackType } from 'constants/type';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCenterStore } from 'store/auth';
import styled from 'styled-components';
import { Flex, Span, Container, NullDataBox } from 'styles/style';

const FeedbackPage = () => {
  const [filter, setFilter] = useState<false | null>(null); //false: 응답 없는것, null: 전체
  const { center } = useCenterStore();
  const [feedbackList, setFeedbackList] = useState<FeedbackType[]>([]);
  const [offset, setOffset] = useState(0); // 추가

  //스크롤 처리
  const [totalCount, setTotalCount] = useState(0);
  const lastRef = useRef(null);
  const [observe, unobserve] = useIntersectionObserver(() => {
    setOffset((offset) => offset + 10);
  });

  useEffect(() => {
    getFeedbackList();
  }, []);
  useEffect(() => {
    getFeedbackList();
  }, [offset]);
  useEffect(() => {
    setOffset(0);
    getFeedbackList();
  }, [filter]);
  useEffect(() => {
    observe(lastRef.current);
    if (totalCount <= feedbackList.length || feedbackList.length === 0) {
      unobserve(lastRef.current);
    }
  }, [feedbackList]);
  const getFeedbackList = async () => {
    const response = await postFeedbackList({
      centerId: center.centerId,
      failedToComplete: 'false',
      orderBy: 'desc',
      responseData: filter,
      limit: 10,
      offset,
    });

    const result = response;
    if (!result) return;

    const { feedbackList, listInfo } = result;
    if (listInfo) {
      setTotalCount(listInfo.totalCount);
    }

    if (feedbackList?.length > 0 && offset === 0) {
      setFeedbackList(feedbackList);
    } else if (feedbackList) {
      setFeedbackList((prevList) => [...prevList, ...feedbackList]);
    }
  };
  return (
    <>
      <Container>
        <Flex justifyContents='space-between' margin='0 0 35px 0'>
          <Span fontSize='18px' fontWeight='700' whiteSpace='nowrap'>
            피드백 요청 리스트
          </Span>
          {filter === null ? (
            <StatusButton onClick={() => setFilter(false)}>
              <Icon icon='ToggleCheckButton' width={16} height={16} />
              <Span fontSize='16px'>미완료만</Span>
            </StatusButton>
          ) : (
            <StatusButton onClick={() => setFilter(null)}>
              <Icon icon='ToggleCheckButton_Active' width={16} height={16} />
              <Span fontSize='16px' color='var(--primary)'>
                미완료만
              </Span>
            </StatusButton>
          )}
        </Flex>
        <Flex flexDirection='column' position='relative'>
          {feedbackList.length > 0 ? (
            <>
              {feedbackList.map((feedback: FeedbackType, i: number) => {
                return (
                  <Flex key={feedback.feedbackId + i}>
                    <Link
                      to={`./detail?feedbackId=${feedback.feedbackId}&sessionId=${feedback.sessionId}`}
                    >
                      <FeedbackItem feedback={feedback} />
                    </Link>
                  </Flex>
                );
              })}
              <div ref={lastRef} style={{ height: '10px' }}></div>
            </>
          ) : (
            <NullDataBox>피드백 요청이 없습니다</NullDataBox>
          )}
          {feedbackList?.length > 0 && <Line />}
        </Flex>
      </Container>
    </>
  );
};
export default FeedbackPage;

const StatusButton = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;
