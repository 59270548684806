import styled from 'styled-components';
export const Container = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 40px 30px;
  background-color: #1e1e1e;
  color: #21c37d;
`;
export const Title = styled.div`
  font-size: 2rem;
  padding: 0 10px 16px 10px;
  border-bottom: 1px solid #e5e8eb33;
  margin-bottom: 5px;
  color: var(--c0);
  font-weight: bold;
`;
export const Contents = styled.div`
  padding: 10px;
`;
export const TermBody = styled.div`
  padding: 10px;
`;
export const TermTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 25px;
  padding-bottom: 16px;
  color: #21c37d;
`;
export const TermBox = styled.div`
  font-size: 1.4rem;
  color: #4e5968;
  font-weight: 400;
  line-height: 21px;
`;
export const Dl = styled.dl`
  margin: 0;
`;
export const Dt = styled.dt`
  font-size: 1.7rem;
  line-height: 25px;
  margin-top: 30px;
  padding-bottom: 10px;
  color: #21c37d;
  :first-child {
    margin-top: 0;
  }
`;
export const Dd = styled.dd`
  font-size: 1.4rem;
  color: #4e5968;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: white;
`;
export const Table = styled.table`
  border-top: solid 1px white;
`;
export const Th = styled.th`
  text-align: left;
  font-size: 1.4rem;
  color: var(--c0);
  line-height: 21px;
  padding: 16px 8px;
  border-bottom: solid 1px #e5e8eb54;
  font-weight: 500;
  min-width: 110px;
`;
export const Td = styled.td`
  text-align: left;
  font-size: 1.4rem;
  color: white;
  font-weight: 400;
  line-height: 21px;
  padding: 16px 8px;
  border-bottom: solid 1px #e5e8eb54;
`;
