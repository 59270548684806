import styled from 'styled-components';

export const RelativeDiv = styled.div`
  position: relative;
  margin-top: 200px;
  height: 150px;
  padding: 0 50px;
`;
export const Contaier = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  gap: 80px;
  justify-content: center;
  align-items: center;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: var(--c500);
    letter-spacing: -0.2px;
    white-space: nowrap;
  }
`;
export const CSContainer = styled.div`
  width: 296px;
  height: 71px;
  display: flex;
  padding: 16px;
  justify-content: center;
  gap: 6px;
  align-items: flex-start;
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid var(--c400, #b0b2bf);
  flex-shrink: 0;
`;
export const WrapIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
