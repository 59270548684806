import styled from 'styled-components';
export const Container = styled.div`
  /* padding: 0 15px 15px 15px; */
  margin-top: -12px;
`;
export const Item = styled.div`
  font-size: 1.2rem;
`;

export const AddCommentContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 15px 21px 0;
  padding-bottom: 7px;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid var(--c800);
`;

export const CommentBox = styled.div`
  padding: 10px 21px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  :last-child {
    padding-bottom: 0px;
  }
`;

export const MoreButton = styled.div`
  width: 100%;
  height: 30px;
  font-size: 1.3rem;
  color: var(--c100);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
